
import Vue from "vue";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: 'Login',

  data: () => ({
    username: '',
    password: '',
  }),
  computed: {
    ...mapGetters(['loginError', 'googleAuthUrl'])
  },
  mounted() {
    this.retrieveGoogleAuthUrl();
  },
  methods: {
    ...mapActions(['login', 'retrieveGoogleAuthUrl']),
    triggerLogin() {
      this.login({
        username: this.username,
        password: this.password,
      });
    }
  },
});
