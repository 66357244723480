import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Dashboard from "@/components/Dashboard/Page/Dashboard.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard
  },
  {
    path: '/franchise',
    name: 'Franchises',
    component: () => import(/* webpackChunkName: "franchise" */ '../components/Franchise/Page/FranchiseOverview.vue'),
  },
  {
    path: '/franchise-edit/:franchiseid',
    name: 'Franchise aanpassen',
    component: () => import(/* webpackChunkName: "franchise-edit" */ '../components/Franchise/Page/FranchiseEdit.vue'),
    props: true,
  },
  {
    path: '/postcode-management',
    name: 'Postcode management',
    component: () => import(/* webpackChunkName: "postcode-management" */ '../components/PostcodeManagement/Page/PostcodeOverview.vue'),
  },
  {
    path: '/franchise-invoice',
    name: 'Franchise Facturen',
    component: () => import(/* webpackChunkName: "franchise-invoice" */ '../components/FranchiseInvoice/Page/FranchiseInvoice.vue'),
  },
  {
    path: '/franchise-invoice/create',
    name: 'Franchise Factuur Aanmaken',
    component: () => import(/* webpackChunkName: "franchise-invoice" */ '../components/FranchiseInvoice/Page/FranchiseInvoiceCreate.vue'),
  },
  {
    path: '/content',
    name: 'CMS-Pagina\'s',
    component: () => import(/* webpackChunkName: "content-pages" */ '../components/Content/Page/ContentOverview.vue'),
  },
  {
    path: '/content/:pageId',
    name: 'Pagina aanpassen',
    component: () => import(/* webpackChunkName: "content-pages" */ '../components/Content/Page/ContentEdit.vue'),
    props: true,
  },
  {
    path: '/product-option',
    name: 'Product Opties',
    component: () => import(/* webpackChunkName: "product-options" */ '../components/ProductOption/Page/ProductOptionOverview.vue'),
  },
  {
    path: '/product-option/:optionGroupId',
    name: 'Product Opties Aanpassen',
    component: () => import(/* webpackChunkName: "product-options" */ '../components/ProductOption/Page/ProductOptionEdit.vue'),
    props: true,
  },
  {
    path: '/customer',
    name: 'Klanten',
    component: () => import(/* webpackChunkName: "customer" */ '../components/Customer/Page/CustomerOverview.vue'),
  },
  {
    path: '/customer/statistics',
    name: 'Klant statistieken',
    component: () => import(/* webpackChunkName: "customer" */ '../components/CustomerStatistics/Page/CustomerStatistics.vue'),
  },
  {
    path: '/customer/:customerId',
    name: 'Klant aanpassen',
    component: () => import(/* webpackChunkName: "customer" */ '../components/Customer/Page/CustomerEdit.vue'),
    props: true,
  },
  {
    path: '/offer',
    name: 'Offertes',
    component: () => import(/* webpackChunkName: "offer" */ '../components/Offer/Page/OfferOverview.vue'),
  },
  {
    path: '/offer/:offerId',
    name: 'Offerte opstellen',
    component: () => import(/* webpackChunkName: "offer" */ '../components/Offer/Page/OfferEdit.vue'),
    props: true,
  },
  {
    path: '/order/to-invoice',
    name: 'Bestellingen omzetten naar facturen',
    component: () => import(/* webpackChunkName: "order" */ '../components/OrdersToInvoice/Page/Overview.vue'),
  },
  {
    path: '/order/to-merge-invoice',
    name: 'Bestellingen omzetten naar verzamelfacturen',
    component: () => import(/* webpackChunkName: "order" */ '../components/OrdersToMergeInvoice/Page/Overview.vue'),
  },
  {
    path: '/order',
    name: 'Bestellingen',
    component: () => import(/* webpackChunkName: "order" */ '../components/Order/Page/OrderOverview.vue'),
  },
  {
    path: '/order/new',
    name: 'Bestelling aanmaken',
    component: () => import(/* webpackChunkName: "order" */ '../components/Order/Page/OrderCreate.vue'),
    props: true,
  },
  {
    path: '/order/:orderId',
    name: 'Bestelling aanpassen',
    component: () => import(/* webpackChunkName: "order" */ '../components/Order/Page/OrderEdit.vue'),
    props: true,
  },
  {
    path: '/invoice',
    name: 'Facturen',
    component: () => import(/* webpackChunkName: "invoice" */ '../components/Invoice/Page/InvoiceOverview.vue'),
  },
  {
    path: '/invoice/debitors',
    name: 'Debiteuren',
    component: () => import(/* webpackChunkName: "debitors" */ '../components/Debitors/Page/Debitors.vue'),
    props: true,
  },
  {
    path: '/invoice/:invoiceId',
    name: 'Factuur aanpassen',
    component: () => import(/* webpackChunkName: "invoice" */ '../components/Invoice/Page/InvoiceEdit.vue'),
    props: true,
  },
  {
    path: '/product',
    name: 'Producten',
    component: () => import(/* webpackChunkName: "product" */ '../components/Product/Page/ProductOverview.vue'),
  },
  {
    path: '/product/:productId',
    name: 'Product aanpassen',
    component: () => import(/* webpackChunkName: "product" */ '../components/Product/Page/ProductEdit.vue'),
    props: true,
  },
  {
    path: '/product-category/:categoryId',
    name: 'Categorie aanpassen',
    component: () => import(/* webpackChunkName: "product-category" */ '../components/Category/Page/CategoryEdit.vue'),
    props: true,
  },
  {
    path: '/route-planner',
    name: 'Route planner',
    component: () => import(/* webpackChunkName: "route-planner" */ '../components/RoutePlanner/Page/RoutePlanner.vue'),
    props: true,
  },
  {
    path: '/settings/opening-times',
    name: 'Openingstijden',
    component: () => import(/* webpackChunkName: "opening-times" */ '../components/OpeningTimes/Page/OpeningTimesPage.vue'),
    props: true,
  },
  {
    path: '/settings',
    name: 'Instellingen',
    component: () => import(/* webpackChunkName: "settings" */ '../components/Settings/Page/Settings.vue'),
  },
  {
    path: '/google-redirect',
    name: 'Google redirect',
    component: () => import(/* webpackChunkName: "settings" */ '../components/LoginSocial/LoginSocial.vue'),
  },
  {
    path: '/reports',
    name: 'Rapportages',
    component: () => import(/* webpackChunkName: "settings" */ '../components/Reports/Page/Reports.vue'),
  },
  {
    path: '/postcodes',
    name: 'Postcodes',
    component: () => import(/* webpackChunkName: "postcodes" */ '../components/Postcodes/Page/Postcodes.vue'),
  },
  {
    path: '/connect-bank',
    name: 'Connect Bank',
    component: () => import(/* webpackChunkName: "connect-bank" */ '../components/ConnectBank/Page/ConnectBank.vue'),
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "transaction" */ '../components/Transaction/Page/Transaction.vue'),
  },
  {
    path: '/kitchen-list',
    name: 'KitchenList',
    component: () => import(/* webpackChunkName: "kitchen-list" */ '../components/KitchenList/Page/KitchenList.vue'),
  },
  {
    path: '/discount-codes',
    name: 'GlobalDiscountCodes',
    component: () => import(/* webpackChunkName: "discount-codes" */ '../components/GlobalDiscountCodes/Pages/GlobalDiscountCodes.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
