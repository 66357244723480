<template>
  <v-main>
    <v-container fluid class="login">
      <div>Redirecting...</div>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: "LoginSocial",
  mounted() {
    if (this.$route.query.code) {
      this.$store.commit('setGoogleAuthCode', this.$route.query.code);
      this.$store.dispatch('loginWithGoogleToken');

      return;
    }

    this.$router.push('/login');
  }
})
</script>

<style scoped>
.login {
  min-height: 100vh;
  background-color: #d2d6de;
}
</style>
