import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{attrs:{"app":"","color":"green","dark":"","dense":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.preventDefault();return _vm.toggleMenu.apply(null, arguments)}}}),_c(VToolbarTitle,[_vm._v("Company Sandwich")]),_c(VSpacer),_c(VMenu,{attrs:{"right":"","bottom":"","open-on-hover":"","nudge-bottom":"30"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"transparent","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$store.getters.currentFranchise)+" "),_c(VIcon,[_vm._v("mdi-account")])],1)]}}])},[_c(VList,[(_vm.$store.getters.overWrittenFranchise)?_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,{on:{"click":function($event){$event.preventDefault();return _vm.switchToSuperUser.apply(null, arguments)}}},[_vm._v("Login als franchisegever")])],1):_vm._e(),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,{on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/settings')}}},[_vm._v("Instellingen")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,{on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/connect-bank')}}},[_vm._v("Koppel bankrekening")])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,{on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Uitloggen")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }