export default class DefaultState{
  loading = false;
  categoryId: string|null = null;
  title = '';
  seoTitle = '';
  urlFriendlyName = '';
  description = '';
  metaKeywords = '';
  metaDescription = '';
  currentImageUrl: string|null = null;
  file: Blob|string|null = null;
}
