import {AxiosError} from "axios";
import Vue from "vue";

export interface StoreContext {
  state: DefaultState,
  commit: any,
  dispatch: any,
  getters: any,
}

interface Bank {
  id: string,
  name: string,
  logo: string,
}

class DefaultState {
  loading: boolean = false;
  bankConnected: boolean = false;
  bankId: string|null = null;
  connectionLink: string|null = null;
  chosenBank: string = '';
  banks: Bank[] = [];
}

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setBanks(state: DefaultState, banks: Bank[]): void {
      state.banks = banks;
    },
    setChosenBank(state: DefaultState, chosenBank: string): void {
      state.chosenBank = chosenBank;
    },
    setConnectionLink(state: DefaultState, connectionLink: string): void {
      state.connectionLink = connectionLink;
    },
    setBankConnected(state: DefaultState, bankConnected: boolean): void {
      state.bankConnected = bankConnected;
    },
  },
  actions: {
    fetchBanks(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService
        .get('payment/banks')
        .then((data: {banks: Bank[], connectionLink: string|null, connectedAccount: boolean}): void => {
          context.commit('setBanks', data.banks);
          context.commit('setBankConnected', data.connectedAccount);
          if (data.connectionLink !== null) {
            context.commit('setConnectionLink', data.connectionLink);
          }
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .finally(() => context.commit('doneLoading'));
    },
    fetchLink(context: StoreContext): void {
      if (context.getters.chosenBank.length === 0) {
        return;
      }

      context.commit('startLoading');
      Vue.prototype.$backendRequestService
        .post('payment/get-link', {bankId: context.getters.chosenBank})
        .then((data: {link: string}): void => {
          context.commit('setConnectionLink', data.link);
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .finally(() => context.commit('doneLoading'));
    },
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    banks(state: DefaultState): Bank[] {
      return state.banks;
    },
    chosenBank(state: DefaultState): string {
      return state.chosenBank;
    },
    connectionLink(state: DefaultState): string|null {
      return state.connectionLink;
    },
    bankConnected(state: DefaultState): boolean {
      return state.bankConnected;
    },
  }
}
