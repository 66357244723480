import Vue from "vue";
import DefaultState from "@/components/ResetPassword/ValueObject/DefaultState";
import {StoreContext} from "@/components/ResetPassword/ValueObject/StoreContext";
import {AxiosError} from "axios";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    setResetPasswordError(state: DefaultState, error: string|null): void {
      state.resetPasswordError = error;
    },
    setEmail(state: DefaultState, email: string): void {
      state.email = email;
    },
    setPassword(state: DefaultState, password: string): void {
      state.password = password;
    },
    setRePassword(state: DefaultState, rePassword: string): void {
      state.rePassword = rePassword;
    },
    resetPasswordRequested(state: DefaultState): void {
      state.resetPasswordRequested = true;
    },
    setToken(state: DefaultState, token: string): void {
      state.token = token;
    },
  },
  actions: {
    sendResetPasswordMail(context: StoreContext): void {
      const params = {
        email: context.state.email
      };
      Vue.prototype.$backendRequestService.post('admin/reset-password', params)
        .then(() => context.commit('resetPasswordRequested'))
        .catch((error: AxiosError) => {
          context.commit('addError', error, {root: true});
          throw error;
        });
    },
    saveNewPassword(context: StoreContext): void {
      const params = {
        token: context.state.token,
        password: context.state.password,
        rePassword: context.state.rePassword
      };

      return Vue.prototype.$backendRequestService.post('admin/save-password', params)
        .then(() => {
          context.commit('addSuccessMessage', 'Wachtwoord opgeslagen');
        })
        .catch((error: AxiosError) => {
          context.commit('addError', error, {root: true});
          throw error;
        });
    },
  },
  getters: {
    resetPasswordError(state: DefaultState): string|null {
      return state.resetPasswordError;
    },
    email(state: DefaultState): string {
      return state.email;
    },
    password(state: DefaultState): string {
      return state.password;
    },
    rePassword(state: DefaultState): string {
      return state.rePassword;
    },
    resetPasswordRequested(state: DefaultState): boolean {
      return state.resetPasswordRequested;
    },
    token(state: DefaultState): string|null {
      return state.token;
    },
  }
}
