import DefaultState from "@/components/Customer/ValueObject/DefaultState";
import {Address} from "@/components/Customer/ValueObject/Address";
import {StoreContext} from "@/components/Customer/ValueObject/StoreContext";
import Vue from "vue";
import {AxiosError} from "axios";
import {Customer} from "@/components/Customer/ValueObject/Customer";
import {DiscountCoupon} from "@/components/Customer/ValueObject/DiscountCoupon";
import DateObject from "@/service/DateObject";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    activateDialog(state: DefaultState): void {
      state.dialogActivated = true;
    },
    deactivateDialog(state: DefaultState): void {
      state.dialogActivated = false;
    },
    setCurrentAddress(state: DefaultState, address: Address): void {
      Object.assign(state.currentAddress, address);
    },
    resetCurrentAddress(state: DefaultState): void {
      state.currentAddress = {
        id: null,
        streetAndHouseNumber: '',
        postcode: '',
        city: '',
        default: false,
        billingAddress: false,
        contactName: null,
        contactPhoneNumber: null,
        companyName: null,
      };
    },
    setTotalCustomers(state: DefaultState, totalCustomers: number): void {
      state.totalCustomers = totalCustomers;
    },
    setCustomers(state: DefaultState, customers: Customer[]): void {
      state.customers = customers;
    },
    setPage(state: DefaultState, page: number): void {
      state.page = page;
    },
    setSearchCriteria(state: DefaultState, searchCriteria: string): void {
      state.searchCriteria = searchCriteria;
    },
    setLoading(state: DefaultState, loading: boolean): void {
      state.loading = loading;
    },
    setSearchTimeout(state: DefaultState, searchTimeout: ReturnType<typeof setTimeout>|null): void {
      state.searchTimeout = searchTimeout;
    },
    setCustomer(state: DefaultState, customerId: string): void {
      state.customerId = customerId;
    },
    setDiscountCoupons(state: DefaultState, coupons: DiscountCoupon[]): void {
      state.discountCoupons = coupons;
    },
    setCreateCouponError(state: DefaultState, createCouponError: null|string): void {
      state.createCouponError = createCouponError;
    },
    setCouponDialogActivated(state: DefaultState, couponDialogActivated: boolean): void {
      state.couponDialogActivated = couponDialogActivated;
    }
  },
  actions: {
    removeCustomer(context: StoreContext, customerId: string): Promise<unknown> {
      return Vue.prototype.$backendRequestService
        .post(`customer/${customerId}/remove`)
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    setSearchCriteria(context: StoreContext, searchCriteria: string): void {
      context.commit('setSearchCriteria', searchCriteria);
      if (context.state.searchTimeout !== null) {
        clearTimeout(context.state.searchTimeout);
      }

      context.commit('setPage', 1);
      context.commit(
        'setSearchTimeout',
        setTimeout(() => context.dispatch('fetchCustomers'), 400)
      );
    },
    fetchCustomers(context: StoreContext): void {
      const params = {
        page: context.state.page,
        amountPerPage: context.state.amountPerPage,
        searchCriteria: context.state.searchCriteria,
      };

      context.commit('setLoading', true);
      Vue.prototype.$backendRequestService.get('customers', params)
        .then((data: {customers: Customer[], totalCustomers: number}) => {
          context.commit('setCustomers', data.customers);
          context.commit('setTotalCustomers', data.totalCustomers);
          context.dispatch('setUrl')
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .finally(() => {
          context.commit('setLoading', false);
        });
    },
    setUrl(context: StoreContext): void {
      const esc = encodeURIComponent;
      const query = `page=${context.state.page}&search_query=${esc(context.state.searchCriteria)}`;
      window.history.pushState(
        {},
        'Klanten',
        '/customer?' + query
      );
    },
    readUrlForParams(context: StoreContext): void {
      const urlParams = new URLSearchParams(window.location.search);
      const query = urlParams.get('search_query');
      if (query !== null) {
        context.commit('setSearchCriteria', query);
      }
      const page = urlParams.get('page');
      if (page !== null) {
        context.commit('setPage', parseInt(page));
      }
    },
    fetchCoupons(context: StoreContext): void {
      const customerId = context.state.customerId;
      if (!customerId || customerId === 'new') {
        return;
      }

      Vue.prototype.$backendRequestService.get(`customer/${customerId}/coupons`)
        .then((data: {coupons: DiscountCoupon[]}) => {
          context.commit('setDiscountCoupons', data.coupons);
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
    },
    createCoupon(context: StoreContext, data: {
      uniqueCode: string,
      discountPercentage: number,
      expiryDate: DateObject,
    }): void {
      const customerId = context.state.customerId;
      if (!customerId) {
        context.commit('setCreateCouponError', 'Er is geen klant geselecteerd')
        return;
      }

      Vue.prototype.$backendRequestService.post(`customer/${customerId}/coupons/create`, {
        uniqueCode: data.uniqueCode,
        discountPercentage: data.discountPercentage,
        expiryDate: data.expiryDate.toSystemDate(),
      })
        .then(() => {
          context.dispatch('fetchCoupons');
          context.commit('setCouponDialogActivated', false);
          context.commit('addSuccessMessage', 'Kortingscode succesvol toegevoegd', {root: true})
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
    }

  },
  getters: {
    dialogActivated(state: DefaultState): boolean {
      return state.dialogActivated;
    },
    currentAddress(state: DefaultState): Address {
      return state.currentAddress;
    },
    customers(state: DefaultState): Customer[] {
      return state.customers;
    },
    page(state: DefaultState): number {
      return state.page;
    },
    searchCriteria(state: DefaultState): string {
      return state.searchCriteria;
    },
    amountPerPage(state: DefaultState): number {
      return state.amountPerPage;
    },
    totalCustomers(state: DefaultState): number {
      return state.totalCustomers;
    },
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    discountCoupons(state: DefaultState): DiscountCoupon[] {
      return state.discountCoupons;
    },
    createCouponError(state: DefaultState): null|string {
      return state.createCouponError;
    },
    couponDialogActivated(state: DefaultState): boolean {
      return state.couponDialogActivated;
    }
  }
}
