import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.resetPasswordRequested && !_vm.token)?_c(VCol,{attrs:{"align":"center"}},[_c(VForm,[_c(VCard,{attrs:{"width":"400"}},[_c(VCardTitle,[_c(VCol,{attrs:{"align-self":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"alt":"Company Sandwich logo","max-width":"125"}})],1)],1),_c(VCardText,{staticClass:"password-requested"},[_vm._v(" In het geval dat uw e-mail bekend is ontvangt u binnen een paar minuten een e-mail waarin de stappen staan om uw wachtwoord te resetten. ")]),_c(VCardActions,[_c(VBtn,{attrs:{"type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/')}}},[_vm._v("Terug naar inloggen")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }