import DefaultState from "@/components/RoutePlanner/ValueObjects/DefaultState";
import {StoreContext} from "@/components/RoutePlanner/ValueObjects/StoreContext";
import Vue from "vue";
import {CustomRouteSuggestion} from "@/components/RoutePlanner/ValueObjects/CustomRouteSuggestion";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setSuggestions(state: DefaultState, customRouteSuggestions: CustomRouteSuggestion[]): void {
      state.customRouteSuggestions = customRouteSuggestions;
    },
  },
  actions: {
    fetchSuggestions(context: StoreContext, parameters: Record<string, unknown>): void {
      if (context.state.suggestionTimeout !== null) {
        clearTimeout(context.state.suggestionTimeout);
      }

      context.commit('startLoading');
      context.state.suggestionTimeout = setTimeout(
        function () {
          context.dispatch('_fetchSuggestions', parameters);
        },
        400
      );
    },
    _fetchSuggestions(context: StoreContext, parameters: Record<string, unknown>): void {
      Vue.prototype.$backendRequestService.post('routeplanner/suggestions', parameters).then((data: {suggestions: CustomRouteSuggestion[]}) => {
        context.commit('setSuggestions', data.suggestions);
      }).catch(Vue.prototype.showError).finally(() => context.commit('doneLoading'));
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    customRouteSuggestions(state: DefaultState): CustomRouteSuggestion[] {
      return state.customRouteSuggestions;
    },
  }
}
