import {Address} from "@/components/Customer/ValueObject/Address";
import {Customer} from "@/components/Customer/ValueObject/Customer";
import {DiscountCoupon} from "@/components/Customer/ValueObject/DiscountCoupon";

export default class DefaultState {
  dialogActivated = false;
  customerId: null|string = null;
  currentAddress: Address = {
    id: null,
    streetAndHouseNumber: '',
    postcode: '',
    city: '',
    default: false,
    billingAddress: false,
    contactName: null,
    contactPhoneNumber: null,
    companyName: null,
  };
  discountCoupons: DiscountCoupon[] = [];
  createCouponError: null|string = null;
  couponDialogActivated = false;
  // Overview variables
  totalCustomers = 0;
  customers: Customer[] = [];
  page = 1;
  amountPerPage = 25;
  searchCriteria = '';
  loading = true;
  searchTimeout: ReturnType<typeof setTimeout>|null = null;
}
