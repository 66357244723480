import Vue from "vue";
import {StoreContext} from "@/components/Generic/StoreContext";
import {AxiosError} from "axios";
import DateObject from "@/service/DateObject";

interface Transaction {
  bookingDate: DateObject|string;
  amount: number;
  debitorName: string;
  description: string;
}

class DefaultState {
  loading: boolean = false;
  transactions: Transaction[] = [];
  currentDate: DateObject = DateObject.now();
}

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    setLoading(state: DefaultState, loading: boolean): void {
      state.loading = loading;
    },
    setDate(state: DefaultState, date: DateObject): void {
      state.currentDate = date;
    },
    setTransactions(state: DefaultState, transactions: Transaction[]): void {
      state.transactions = transactions;
    },
  },
  actions: {
    fetchTransactions(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get(`transactions/${context.getters.currentDate.toSystemDate()}`)
        .then((data: {transactions: Transaction[]}) => {
          data.transactions = data.transactions.map((transaction: Transaction) => {
            if (typeof transaction.bookingDate === 'string') {
              transaction.bookingDate = DateObject.fromDateString(transaction.bookingDate);
            }

            return transaction;
          });

          context.commit('setTransactions', data.transactions);
        })
        .catch((error: AxiosError) => {
          context.commit('addError', error, {root: true});
          throw error;
        });
    },
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    transactions(state: DefaultState): Transaction[] {
      return state.transactions;
    },
    currentDate(state: DefaultState): DateObject {
      return state.currentDate;
    },
  }
}
