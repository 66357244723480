import Invoice from "./Invoice";
import {Franchise} from "./Franchise";
import {Supply} from "./Supply";
import DateObject from "@/service/DateObject";

export default class DefaultState {
  loading = true;
  creatingInvoice = false;
  franchises: Franchise[] = [];
  pickedFranchise: string|null = null;
  invoices: Invoice[] = [];
  fromDate: DateObject|null = null;
  toDate: DateObject|null = null;
  supplies: Supply[] = [];
  fee = 10;
  alreadyInvoiced = 0;
}
