import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.resetPasswordRequested && !_vm.token)?_c(VCol,{attrs:{"align":"center"}},[_c(VForm,[_c(VCard,{attrs:{"width":"400"}},[_c(VCardTitle,[_c(VCol,{attrs:{"align-self":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"alt":"Company Sandwich logo","max-width":"125"}})],1)],1),_c(VCardText,[_vm._v(" Vul hieronder uw e-mailadres in en indien u een account heeft dan zal u binnen een aantal minuten een e-mail ontvangen waarin staat hoe u uw wachtwoord opnieuw kunt instellen. ")]),_c(VCardText,[_c(VTextField,{attrs:{"id":"email","label":"E-mailadres","hide-details":""},model:{value:(_vm._email),callback:function ($$v) {_vm._email=$$v},expression:"_email"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","id":"send-email","type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm.sendResetPasswordMail.apply(null, arguments)}}},[_vm._v("Versturen")]),_c(VBtn,{attrs:{"type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/')}}},[_vm._v("Terug naar inloggen")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }