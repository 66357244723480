import Vue from "vue";
import DefaultState from "@/components/PostcodeManagement/ValueObject/DefaultState";
import {Franchise} from "@/components/PostcodeManagement/ValueObject/Franchise";
import {PostCodeListInterface} from "@/components/PostcodeManagement/ValueObject/PostCodeListInterface";
import {StoreContext} from "@/components/PostcodeManagement/ValueObject/StoreContext";
import {AxiosError} from "axios";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setFranchises(state: DefaultState, franchises: Franchise[]): void {
      state.franchises = franchises;
    },
    setAllFranchises(state: DefaultState, allFranchises: Franchise[]): void {
      state.allFranchises = allFranchises;
    },
    activateDialog(state: DefaultState): void {
      state.dialog = true;
    },
    deactivateDialog(state: DefaultState): void {
      state.dialog = false;
    },
  },
  actions: {
    fetchPostCodes(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('post-code/list').then((data: PostCodeListInterface) => {
        context.commit('setFranchises', data.franchises);
        context.commit('setAllFranchises', data.allFranchises);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    save(context: StoreContext, params: Record<string, unknown>[]): void {
      Vue.prototype.$backendRequestService.post('post-code/add', params).then(() => {
        context.commit('addNotification', 'Postcodes toegevoegd aan franchise', {root: true})
        context.dispatch('fetchPostCodes');
        context.commit('deactivateDialog');
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    franchises(state: DefaultState): Franchise[] {
      return state.franchises;
    },
    allFranchises(state: DefaultState): Franchise[] {
      return state.allFranchises;
    },
    dialogActivated(state: DefaultState): boolean {
      return state.dialog;
    },
  }
}
