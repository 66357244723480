import DateObject from "@/service/DateObject";
import Vue from "vue";
import {AxiosError} from "axios";
import DefaultState from "@/components/OrdersToMergeInvoice/ValueObject/DefaultState";
import {Order} from "@/components/OrdersToMergeInvoice/ValueObject/Order";
import {StoreContext} from "@/store/ValueObject/StoreContext";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setCustomers(state: DefaultState, customers: Order[][]): void {
      state.customers = customers;
    },
  },
  actions: {
    fetchData(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('orders-to-merge-invoice')
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .then((data: {customers: Order[][]}) => {
          context.commit('setCustomers', data.customers.map(customer => {
            return customer.map(order => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              order.orderDate = DateObject.fromDateString(order.orderDate);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              order.deliveryDate = DateObject.fromDateString(order.deliveryDate);

              return order;
            });
          })
          .sort((a: Order[], b: Order[]) => {
            return a.length < b.length ? 1 : -1;
          }));
        })
        .finally(() => context.commit('doneLoading'));
    },
    createInvoice(context: StoreContext, orderIds: string[]): void {
      if (orderIds.length === 0) {
        context.commit('addWarning', 'Geen bestellingen geselecteerd.', {root: true});
        return;
      }

      context.commit('startLoading');
      Vue.prototype.$backendRequestService.post('admin-orders/convert', {orders: orderIds})
        .then(() => {
          context.dispatch('fetchData');
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .finally(() => context.commit('doneLoading'));
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    customers(state: DefaultState): Order[][] {
      return state.customers;
    },
    invoicesConverting(state: DefaultState): string[] {
      return state.invoicesConverting;
    }
  }
}
