import DateObject from "@/service/DateObject";
import OverviewDefaultState from "@/components/Order/ValueObjects/OverviewDefaultState";
import {OverviewStoreContext} from "@/components/Order/ValueObjects/OverviewStoreContext";
import {Order} from "@/components/Order/ValueObjects/Order";
import Vue from "vue";
import {AxiosError} from "axios";
import {AvailableFranchise} from "@/components/Order/ValueObjects/AvailableFranchise";
import {AdminOrdersResponse} from "@/components/Order/ValueObjects/AdminOrdersResponse";

export default {
  state: (): OverviewDefaultState => new OverviewDefaultState(),
  mutations: {
    startLoading(state: OverviewDefaultState): void {
      state.loading = true;
    },
    doneLoading(state: OverviewDefaultState): void {
      state.loading = false;
    },
    setPage(state: OverviewDefaultState, page: number): void {
      state.page = page;
    },
    setSearchQuery(state: OverviewDefaultState, query: string): void {
      state.searchQuery = query;
    },
    setDate(state: OverviewDefaultState, date: DateObject|null): void {
      state.page = 1;
      state.date = date;
    },
    setOrders(state: OverviewDefaultState, orders: Order[]): void {
      state.orders = orders;
    },
    setTotalOrders(state: OverviewDefaultState, totalOrders: number): void {
      state.totalOrders = totalOrders;
    },
    setAvailableFranchises(state: OverviewDefaultState, availableFranchises: AvailableFranchise[]): void {
      state.availableFranchises = availableFranchises;
    },
    setPaymentMethods(state: OverviewDefaultState, paymentMethods: string[]): void {
      state.paymentMethods = paymentMethods;
    },
    setPickedPaymentMethods(state: OverviewDefaultState, paymentMethods: string[]): void {
      state.selectedPaymentMethods = paymentMethods;
    },
    setSelectedFranchises(state: OverviewDefaultState, selectedFranchises: string[]): void {
      state.selectedFranchises = selectedFranchises;
    },
    setIncludeCancelled(state: OverviewDefaultState, includeCancelled: boolean): void {
      state.includeCancelled = includeCancelled;
    },
    setIncludeFutureOrders(state: OverviewDefaultState, includeFutureOrders: boolean): void {
      state.includeFutureOrders = includeFutureOrders;
    },
    setDuplicatingOrder(state: OverviewDefaultState, newState: boolean): void {
      state.duplicatingOrder = newState;
    },
    setFetchingOrderToDuplicate(state: OverviewDefaultState, newState: boolean): void {
      state.fetchingOrderToDuplicate = newState;
    },
  },
  actions: {
    exportOrders(context: OverviewStoreContext): void {
      const params = {
        date: context.state.date ? context.state.date.toSystemDate() : null,
        searchQuery: context.state.searchQuery,
        paymentMethods: context.state.selectedPaymentMethods,
        selectedFranchises: context.state.selectedFranchises,
      };

      Vue.prototype.$backendRequestService.download('orders/export', params)
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    exportOrdersCSV(context: OverviewStoreContext): void {
      const params = {
        date: context.state.date ? context.state.date.toSystemDate() : null,
        searchQuery: context.state.searchQuery,
        paymentMethods: context.state.selectedPaymentMethods,
        selectedFranchises: context.state.selectedFranchises,
      };

      Vue.prototype.$backendRequestService.download('orders/export-csv', params)
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    fetchOrders(context: OverviewStoreContext): void {
      context.commit('startLoading');
      const params = {
        page: context.state.page,
        amountPerPage: context.state.amountPerPage,
        includeCancelled: context.state.includeCancelled,
        includeFutureOrders: context.state.includeFutureOrders,
        date: context.state.date ? context.state.date.toSystemDate() : null,
        searchQuery: context.state.searchQuery,
        paymentMethods: context.state.selectedPaymentMethods,
        selectedFranchises: context.state.selectedFranchises,
      };

      Vue.prototype.$backendRequestService.get('admin-orders', params)
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .then((data: AdminOrdersResponse) => {
          context.commit('setTotalOrders', data.totalOrders);
          context.commit('setAvailableFranchises', data.managedFranchises);
          context.commit('setPaymentMethods', data.paymentMethods);
          context.commit('setOrders', data.orders.map(order => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order.orderDate = DateObject.fromDateString(order.orderDate);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order.deliveryDate = DateObject.fromDateString(order.deliveryDate);

            return order;
          }));
          context.dispatch('updateUrl');
        })
        .finally(() => context.commit('doneLoading'));
    },
    updateUrl(context: OverviewStoreContext): void {
      const params: {
        page: number,
        amountPerPage: number,
        includeCancelled: boolean,
        includeFutureOrders: boolean,
        date: string,
        query: string,
      } = {
        page: context.state.page,
        amountPerPage: context.state.amountPerPage,
        includeCancelled: context.state.includeCancelled,
        includeFutureOrders: context.state.includeFutureOrders,
        date: context.state.date !== null ? context.state.date.toSystemDate() : '',
        query: context.state.searchQuery,
      };

      const esc = encodeURIComponent;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const query = Object.keys(params).map((k: string) => esc(k) + '=' + esc(params[k]))
        .join('&');
      window.history.pushState(
        {},
        'Orders',
        '/order?' + query
      );
    },
    clearFilters(context: OverviewStoreContext): void {
      context.state.paymentMethods = [];
      context.state.selectedPaymentMethods = [];
      context.state.selectedFranchises = [];
      context.state.searchQuery = '';
      context.state.date = null;
      context.state.includeCancelled = true;
      context.dispatch('fetchOrders');
    },
    fetchOrderToDuplicate(context: OverviewStoreContext, orderId: string): void {
      context.commit('setDuplicatingOrder', true);
      context.commit('setFetchingOrderToDuplicate', true);
      context.state.orderIdToDuplicate = orderId;
      Vue.prototype.$backendRequestService.get(`admin-orders/order/${orderId}`).then((data: Order) => {
        context.state.orderToDuplicate = data;
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('setFetchingOrderToDuplicate', false));
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    repeatOrder(context: OverviewStoreContext, data: {deliveryMoment: string}): void {
      if (!context.state.orderIdToDuplicate) {
        return context.commit('addWarning', 'Geen order geselecteerd');
      }

      context.commit('setFetchingOrderToDuplicate', true);
      Vue.prototype.$backendRequestService.post(`admin-order/repeat-order/${context.state.orderIdToDuplicate}`, data).then(() => {
        context.dispatch('fetchOrders');
        context.commit('setDuplicatingOrder', false);
        context.commit('addSuccessMessage', 'Bestelling toegevoegd', {root: true})
      }).catch((error: AxiosError) => context.commit('addError', error)).finally(() => context.commit('setFetchingOrderToDuplicate', false));
    },
  },
  getters: {
    loading(state: OverviewDefaultState): boolean {
      return state.loading;
    },
    date(state: OverviewDefaultState): DateObject|null {
      return state.date;
    },
    orders(state: OverviewDefaultState): Order[] {
      return state.orders;
    },
    page(state: OverviewDefaultState): number {
      return state.page;
    },
    amountPerPage(state: OverviewDefaultState): number {
      return state.amountPerPage;
    },
    totalOrders(state: OverviewDefaultState): number {
      return state.totalOrders;
    },
    includeCancelled(state: OverviewDefaultState): boolean {
      return state.includeCancelled;
    },
    includeFutureOrders(state: OverviewDefaultState): boolean {
      return state.includeFutureOrders;
    },
    searchQuery(state: OverviewDefaultState): string {
      return state.searchQuery;
    },
    searchTimeout(state: OverviewDefaultState): ReturnType<typeof setTimeout>|null {
      return state.searchTimeout;
    },
    paymentMethods(state: OverviewDefaultState): string[] {
      return state.paymentMethods;
    },
    selectedPaymentMethods(state: OverviewDefaultState): string[] {
      return state.selectedPaymentMethods;
    },
    selectedFranchises(state: OverviewDefaultState): string[] {
      return state.selectedFranchises;
    },
    availableFranchises(state: OverviewDefaultState): AvailableFranchise[] {
      return state.availableFranchises;
    },
    filtersChosen(state: OverviewDefaultState): boolean {
      return state.searchQuery.length > 0
        || state.selectedFranchises.length > 0
        || state.selectedPaymentMethods.length > 0
        || state.date !== null;
    },
    duplicatingOrder(state: OverviewDefaultState): boolean {
      return state.duplicatingOrder;
    },
    fetchingOrderToDuplicate(state: OverviewDefaultState): boolean {
      return state.fetchingOrderToDuplicate;
    },
    orderToDuplicate(state: OverviewDefaultState): Order|null {
      return state.orderToDuplicate;
    },
  }
}
