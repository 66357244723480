import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.token)?_c(VCol,{attrs:{"align":"center"}},[_c(VForm,[_c(VCard,{attrs:{"width":"400"}},[_c(VCardTitle,[_c(VCol,{attrs:{"align-self":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"alt":"Company Sandwich logo","max-width":"125"}})],1)],1),_c(VCardText,[_vm._v("Vul hieronder een nieuw en sterk wachtwoord in.")]),_c(VCardText,[_c(VTextField,{attrs:{"type":"password","id":"_password","label":"Wachtwoord","hide-details":""},model:{value:(_vm._password),callback:function ($$v) {_vm._password=$$v},expression:"_password"}}),_c(VTextField,{attrs:{"type":"password","id":"_rePassword","label":"Wachtwoord opnieuw","hide-details":""},model:{value:(_vm._rePassword),callback:function ($$v) {_vm._rePassword=$$v},expression:"_rePassword"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm._saveNewPassword.apply(null, arguments)}}},[_vm._v("Opslaan")]),_c(VBtn,{attrs:{"type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/')}}},[_vm._v("Terug naar inloggen")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }