import DateObject from "../service/DateObject";
import {StoreContext} from "@/store/ValueObject/StoreContext";

const storageKey = 'adcs';

export default (store: StoreContext): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.subscribe((mutation: never, state: Record<string, unknown>) => {
        const data = {
            userToken: state.userToken,
            overWriteFranchise: state.overWriteFranchise || null,
            storageMoment: DateObject.now().toSystemDateTime()
        };
        window.localStorage.setItem(storageKey, JSON.stringify(data));
    })

    const item = window.localStorage.getItem(storageKey);
    if (item === null) {
        return;
    }

    const previousState = JSON.parse(item);
    if (previousState === null) {
        return;
    }

    if (previousState.storageMoment) {
        const lastStorageMoment = DateObject.fromDateString(previousState.storageMoment);
        if (lastStorageMoment.isBefore(DateObject.now().addDays(-2))) {
            previousState.userToken = false;
        }
    }

    if (previousState.userToken) {
        store.commit('setUserToken', previousState.userToken);
    }

    if (previousState.overWriteFranchise) {
        store.commit('loginAsFranchise', previousState.overWriteFranchise)
    }
}
