import DefaultState from "../ValueObject/DefaultState";
import {StoreContext} from "../ValueObject/StoreContext";
import {DashboardResponse} from "../ValueObject/DashboardResponse";
import DateObject from "../../../service/DateObject";
import {Franchise} from "../ValueObject/Franchise";
import {StatisticsResponse} from "../ValueObject/StatisticsResponse";
import DeliveriesForDate from "../ValueObject/DeliveriesForDate";
import RevenueForDate from "../ValueObject/RevenueForDate";
import Vue from "vue";
import AnnualData from "@/components/Dashboard/ValueObject/AnnualData";
import CurrentMonth from "@/components/Dashboard/ValueObject/CurrentMonth";
import {AxiosError} from "axios";

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState(
    DateObject.now().year(),
  )),
  mutations: {
    setOrdersTomorrow(state: DefaultState, ordersTomorrow: number): void {
      state.ordersTomorrow = ordersTomorrow;
    },
    setOrdersDate(state: DefaultState, date: DateObject): void {
      state.date = date;
    },
    setFranchises(state: DefaultState, franchises: Franchise[]): void {
      state.franchises = franchises;
    },
    startedLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setDeliveryData(state: DefaultState, deliveryData: DeliveriesForDate[]): void {
      state.deliveriesByDates = deliveryData;
    },
    setRevenueData(state: DefaultState, revenueData: RevenueForDate[]): void {
      state.revenuesByDates = revenueData;
    },
    setCurrentYear(state: DefaultState, year: number): void {
      state.currentYear = year;
    },
    setTotalOrders(state: DefaultState, orders: number): void {
      state.totalOrders = orders;
    },
    setTotalRevenue(state: DefaultState, revenue: number): void {
      state.totalRevenue = revenue;
    },
    startLoadingStatistics(state: DefaultState): void {
      state.loadingStatistics = true;
    },
    finishLoadingStatistics(state: DefaultState): void {
      state.loadingStatistics = false;
    },
    setAnnualData(state: DefaultState, annualData: AnnualData): void {
      state.annualData = annualData;
    },
    setCurrentMonthData(state: DefaultState, currentMonth: CurrentMonth): void {
      state.currentMonth = currentMonth;
    },
    setProductTotalTomorrow(state: DefaultState, productTotalTomorrow: number): void {
      state.productTotalTomorrow = productTotalTomorrow;
    },
  },
  actions: {
    fetchDashboardData(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get('dashboard')
        .then((data: DashboardResponse) => {
          context.commit('setOrdersTomorrow', data.ordersTomorrow.orders);
          context.commit('setOrdersDate', DateObject.fromDateString(data.ordersTomorrow.day));
          context.commit('setFranchises', data.franchises);
          context.commit('setProductTotalTomorrow', data.productTotalTomorrow);
          context.commit('doneLoading');
        })
        .catch((error: AxiosError) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.message === "Network Error" || error.response.status === 403) {
            context.dispatch('logout');
            return;
          }

          context.commit('addError', error);
        });
    },
    fetchStatistics(context: StoreContext): void {
      context.commit('startLoadingStatistics');
      const params = {
        year: context.getters.currentYear,
        month: DateObject.now().month(),
      };

      Vue.prototype.$backendRequestService.get('admin-statistics', params)
        .then((data: StatisticsResponse) => {
          context.commit('setTotalOrders', data.totalDeliveries);
          context.commit('setTotalRevenue', data.totalRevenue);
          context.commit('setDeliveryData', data.deliveryData);
          context.commit('setRevenueData', data.revenueData);
          context.commit('setAnnualData', data.annualData);
          context.commit('setCurrentMonthData', data.currentMonth);
        })
        .catch((error: AxiosError) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.message === "Network Error" || error.response.status === 403) {
            context.dispatch('logout');
            return;
          }

          context.commit('addError', error);
        })
        .finally(() => context.commit('finishLoadingStatistics'));
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    ordersTomorrow(state: DefaultState): number {
      return state.ordersTomorrow;
    },
    date(state: DefaultState): DateObject {
      return state.date;
    },
    franchises(state: DefaultState): Franchise[]|null {
      return state.franchises;
    },
    deliveryData(state: DefaultState): DeliveriesForDate[] {
      return state.deliveriesByDates;
    },
    revenueData(state: DefaultState): RevenueForDate[] {
      return state.revenuesByDates;
    },
    years(state: DefaultState): number[] {
      let date = DateObject.now().year();
      let years: number[] = [];
      for (let i: number = 0; i < 5; i++) {
        years.unshift(date - i);
      }

      return years;
    },
    months(state: DefaultState): {text: string, value: number}[] {
      return state.months;
    },
    currentYear(state: DefaultState): number {
      return state.currentYear;
    },
    currentMonth(state: DefaultState): CurrentMonth {
      return state.currentMonth;
    },
    totalOrders(state: DefaultState): number {
      return state.totalOrders;
    },
    totalRevenue(state: DefaultState): number {
      return state.totalRevenue;
    },
    loadingStatistics(state: DefaultState): boolean {
      return state.loadingStatistics;
    },
    annualData(state: DefaultState): AnnualData {
      return state.annualData;
    },
    productTotalTomorrow(state: DefaultState): number {
      return state.productTotalTomorrow;
    },
  }
}
