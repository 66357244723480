import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{attrs:{"color":"blue-grey darken-4","mini-variant":_vm.collapsedMenu,"permanent":""},on:{"update:miniVariant":function($event){_vm.collapsedMenu=$event},"update:mini-variant":function($event){_vm.collapsedMenu=$event}}},[_c(VList,{attrs:{"dense":"","nav":"","dark":""}},[_c(VListItemGroup,{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.menuItems),function(item){return _c(VListItem,{key:item.url,attrs:{"link":"","href":item.url},on:{"click":function($event){$event.preventDefault();_vm.$router.push(item.url).catch(() => {})}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,{attrs:{"color":"white"}},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }