<template>
  <v-card :href="$router.resolve({path: `/transactions`}).href" elevation="0" class="transactions">
    <v-img>
      <v-icon class="icon">mdi-currency-eur</v-icon>
    </v-img>
    <v-card-title class="card-title">
      {{ succesfulMatches }} gekoppeld
    </v-card-title>
    <v-card-subtitle>{{ failedMatches }} niet gekoppeld</v-card-subtitle>
    <v-card-text >Transactions laatste 30 dagen</v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "Transactions",
  computed: {
    ...mapGetters({
      succesfulMatches: 'transactionDashboard/succesfulMatches',
      failedMatches: 'transactionDashboard/failedMatches',
    })
  },
  mounted() {
    this.$store.dispatch('transactionDashboard/fetchData');
  }
})
</script>

<style scoped>
.transactions {
  padding: 1rem;
  text-align: center;
}

.card-title {
  text-align: center;
  justify-content: center;
}

.icon {
  font-size: 3rem;
}
</style>
