import {Franchise} from "./Franchise";
import DeliveriesForDate from "./DeliveriesForDate";
import RevenueForDate from "./RevenueForDate";
import DateObject from "../../../service/DateObject";
import AnnualData from "@/components/Dashboard/ValueObject/AnnualData";
import CurrentMonth from "@/components/Dashboard/ValueObject/CurrentMonth";

export default class DefaultState{
  loading = true;
  loadingStatistics = false;
  franchises: Franchise[]|null = null;
  ordersTomorrow = 0;
  productTotalTomorrow = 0;
  date = DateObject.now().addDays(1);
  months: {text: string, value: number}[] = [
    {text: 'januari', value: 1},
    {text: 'februari', value: 2},
    {text: 'maart', value: 3},
    {text: 'april', value: 4},
    {text: 'mei', value: 5},
    {text: 'juni', value: 6},
    {text: 'juli', value: 7},
    {text: 'augustus', value: 8},
    {text: 'september', value: 9},
    {text: 'oktober', value: 10},
    {text: 'november', value: 11},
    {text: 'december', value: 12},
  ];
  deliveriesByDates: DeliveriesForDate[] = [];
  revenuesByDates: RevenueForDate[] = [];
  currentYear: number;
  totalOrders = 0;
  totalRevenue = 0;
  annualData: AnnualData = new AnnualData(DateObject.now().year(), 0, 0, 0, 0);
  currentMonth: CurrentMonth = new CurrentMonth(0, 0);

  constructor(currentYear: number) {
    this.currentYear = currentYear;
  }
}
