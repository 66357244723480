<template>
  <div class="panel">
    <h2>{{ title }}</h2>
    <a v-if="backLink" class="back-link" @click.prevent="$router.push(backLink)">
      <v-list-item-icon><v-icon small>mdi-arrow-left</v-icon> Terug</v-list-item-icon>
    </a>
    <div class="content" :style="{'max-width': maxWidth != null ? `${maxWidth}px` : false}">
      <slot />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Panel",
  props: {
    title: {
      type: String,
      default: 'Default title in panel component'
    },
    backLink: {
      type: String,
      default: null
    },
    maxWidth: {
      type: Number,
      default: null,
    }
  }
})
</script>

<style scoped lang="scss">
.panel {
  padding: .5rem;
  min-height: 80vh;
  background-color: $mainBackgroundColor;
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
  margin: 0;
  padding: .5rem .5rem;
  color: #444;
}

.content {
  padding: .5rem .5rem
}

.back-link {
  font-size: .8em;
  padding: .5rem .5rem;
}
</style>
