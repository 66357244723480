<template>
  <v-app-bar app color="green" dark dense>
    <v-app-bar-nav-icon @click.prevent="toggleMenu" />

    <v-toolbar-title>Company Sandwich</v-toolbar-title>
    <v-spacer />
    <v-menu right bottom open-on-hover nudge-bottom="30">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="transparent" elevation="0" v-bind="attrs" v-on="on">
          {{ $store.getters.currentFranchise }} <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item link v-if="$store.getters.overWrittenFranchise">
          <v-list-item-title @click.prevent="switchToSuperUser">Login als franchisegever</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-title @click.prevent="$router.push('/settings')">Instellingen</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-title @click.prevent="$router.push('/connect-bank')">Koppel bankrekening</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-title @click.prevent="logout">Uitloggen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Vue from "vue";
import {mapActions} from "vuex";

export default Vue.extend({
  name: "TopBar",
  methods: {
    ...mapActions(['logout', 'toggleMenu', 'switchToSuperUser'])
  }
});
</script>

<style scoped>

</style>
