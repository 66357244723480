
import Vue from "vue";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "Notifications",
  computed: {
    ...mapGetters(['notifications']),
  },
});
