import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/src/components/VDialog/VDialog.sass';
import 'vuetify/src/components/VDataTable/VSimpleTable.sass';
import 'vuetify/src/components/VWindow/VWindow.sass';
import 'vuetify/src/components/VDatePicker/VDatePickerYears.sass';
import 'vuetify/src/components/VSlideGroup/VSlideGroup.sass';
import 'vuetify/src/components/VDatePicker/VDatePickerTable.sass';
import 'vuetify/src/components/VTabs/VTabs.sass';
import 'vuetify/src/components/VDatePicker/VDatePickerHeader.sass';
import 'vuetify/src/components/VDataTable/VDataTableHeader.sass';
import 'vuetify/src/components/VDatePicker/VDatePickerTitle.sass';
import 'vuetify/src/components/VDataIterator/VDataFooter.sass';
import 'vuetify/src/components/VPicker/VPicker.sass';

Vue.use(Vuetify);

export default new Vuetify({});
