import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"transactions",attrs:{"href":_vm.$router.resolve({path: `/transactions`}).href,"elevation":"0"}},[_c(VImg,[_c(VIcon,{staticClass:"icon"},[_vm._v("mdi-currency-eur")])],1),_c(VCardTitle,{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.succesfulMatches)+" gekoppeld ")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.failedMatches)+" niet gekoppeld")]),_c(VCardText,[_vm._v("Transactions laatste 30 dagen")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }