import DefaultState from "../ValueObject/DefaultState";
import {StoreContext} from "../ValueObject/StoreContext";
import {Franchise} from "../ValueObject/Franchise";
import {InvoicesResponse} from "../ValueObject/InvoicesResponse";
import Invoice from "../ValueObject/Invoice";
import {Supply} from "../ValueObject/Supply";
import DateObject from "@/service/DateObject";
import Vue from "vue";
import router from "@/router";
import {AxiosError} from "axios";

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    setLoading(state: DefaultState, loading: boolean): void {
      state.loading = loading;
    },
    setCreatingInvoice(state: DefaultState, creatingInvoice: boolean): void {
      state.creatingInvoice = creatingInvoice;
    },
    setFranchises(state: DefaultState, franchises: Franchise[]): void {
      state.franchises = franchises;
    },
    setPickedFranchise(state: DefaultState, franchise: string|null): void {
      state.pickedFranchise = franchise;
    },
    setFromDate(state: DefaultState, value: DateObject|null): void {
      state.fromDate = value;
    },
    setToDate(state: DefaultState, value: DateObject|null): void {
      state.toDate = value;
    },
    setInvoicesforFee(state: DefaultState, value: Invoice[]): void {
      state.invoices = value;
    },
    setFee(state: DefaultState, value: number): void {
      state.fee = value;
    },
    setAlreadyInvoiced(state: DefaultState, value: number): void {
      state.alreadyInvoiced = value;
    },
    addSupply(state: DefaultState, supply: Supply): void {
      state.supplies.push(supply);
    },
    removeSupply(state: DefaultState, index: number): void {
      state.supplies.splice(index, 1);
    },
  },
  actions: {
    fetchFranchises(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get('franchises').then((data: {franchises: {id: string, name: string}[]}) => {
        context.commit('setFranchises', data.franchises);
      }).finally(() => {
        context.commit('setLoading', false);
      });
    },
    fetchInvoices(context: StoreContext): void {
      if (!context.state.pickedFranchise || !context.state.fromDate || !context.state.toDate) {
        return;
      }

      const params = {
        franchise: context.state.pickedFranchise,
        from: context.state.fromDate.toSystemDate(),
        to: context.state.toDate.toSystemDate(),
      };

      context.commit('setLoading', true);
      Vue.prototype.$backendRequestService.get('invoices-for-fee', params).then((data: InvoicesResponse) => {
        context.commit('setInvoicesforFee', data.invoices);
        context.commit('setAlreadyInvoiced', data.alreadyInvoiced);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => {
        context.commit('setLoading', false);
      });
    },
    createFranchiseInvoice(context: StoreContext): void {
      context.commit('setCreatingInvoice', true);
      const params = {
        fromDate: context.state.fromDate ? context.state.fromDate.toSystemDate() : null,
        toDate: context.state.toDate ? context.state.toDate.toSystemDate() : null,
        franchise: context.state.pickedFranchise,
        fee: context.state.fee,
        supplies: context.state.supplies,
      }

      Vue.prototype.$backendRequestService.post('create-franchise-invoice', params).then(() => {
        router.push('/franchise-invoice');
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => {
        context.commit('setCreatingInvoice', false);
      });
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    creatingInvoice(state: DefaultState): boolean {
      return state.creatingInvoice;
    },
    franchises(state: DefaultState): Franchise[] {
      return state.franchises;
    },
    pickedFranchise(state: DefaultState): string|null {
      return state.pickedFranchise;
    },
    fromDate(state: DefaultState): DateObject|null {
      return state.fromDate;
    },
    toDate(state: DefaultState): DateObject|null {
      return state.toDate;
    },
    fee(state: DefaultState): number {
      return state.fee;
    },
    invoices(state: DefaultState): Invoice[] {
      return state.invoices;
    },
    alreadyInvoiced(state: DefaultState): number {
      return state.alreadyInvoiced;
    },
    supplies(state: DefaultState): Supply[] {
      return state.supplies;
    },
    total(state: DefaultState): number {
      let total = 0;
      state.invoices.map(invoice => {
        total += invoice.totalExcludingVat;
      });

      return total;
    },
    totalFee(state: DefaultState): number {
      let total = 0;
      state.invoices.map(invoice => {
        total += (invoice.totalExcludingVat / 100) * state.fee;
      });

      return total;
    },
    grandTotal(state: DefaultState): number {
      let total = 0;
      state.invoices.map(invoice => {
        total += (invoice.totalExcludingVat / 100) * state.fee;
      });

      state.supplies.map(supply => {
        total += supply.price * supply.amount;
      });

      return total;
    },
    invoiceNumbers(state: DefaultState): string {
      return state.invoices.map(invoice => invoice.invoiceNumber).join(', ');
    },
  }
}
