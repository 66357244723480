export default class AnnualData {
  year: number;
  orderCount: number;
  orderTotal: number;
  invoiceCount: number;
  invoiceTotal: number;

  constructor(
    year: number,
    orderCount: number,
    orderTotal: number,
    invoiceCount: number,
    invoiceTotal: number,
  ) {
    this.year = year;
    this.orderCount = orderCount;
    this.orderTotal = orderTotal;
    this.invoiceCount = invoiceCount;
    this.invoiceTotal = invoiceTotal;
  }
}
