import axios from "axios";
import store from "../store/index";

class BackendRequestService {
  baseUrl = '';
  debugPostfix = '';
  requestHeaders: {
    'Authorization': string,
    'Content-Type': string,
    'Accept': string,
    'Franchise-Id': string,
  } = {
    'Authorization': '',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Franchise-Id': '',
  };
  constructor(backendUrl: string) {
    if (store.state.userToken) {
      this.requestHeaders['Authorization'] = store.state.userToken;
    }

    if (process.env.NODE_ENV === 'development') {
      this.debugPostfix = '?XDEBUG_SESSION_START=PHPSTORM';
    }

    this.baseUrl = backendUrl;
  }

  updateFranchiseId(franchiseId: string|null) {
    this.requestHeaders["Franchise-Id"] = franchiseId || '';
  }

  updateToken(token: string) {
    this.requestHeaders.Authorization = token;
  }

  get(url: string, params: Record<string, unknown> = {}) {
    return axios({
      url: this.baseUrl + url + this.debugPostfix,
      method: 'GET',
      headers: this.requestHeaders,
      params,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    }).then(response => response.data);
  }

  post(url: string, data: Record<string, unknown>) {
    return axios({
      url: this.baseUrl + url + this.debugPostfix,
      method: 'POST',
      headers: this.requestHeaders,
      data
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    }).then(response => response.data);
  }

  upload(url: string, data: Record<string, unknown>) {
    const headers: {
      'Authorization': string,
      'Content-Type': string,
      'Accept': string,
      'Franchise-Id': string,
    } = {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Franchise-Id': '',
    };
    Object.assign(headers, this.requestHeaders);

    headers['Content-Type'] = 'multipart/form-data';
    return axios({
      url: this.baseUrl + url + this.debugPostfix,
      method: 'POST',
      headers: headers,
      data
    }).then(response => response.data);
  }

  async download(url: string, params: Record<string, unknown>, suggestedFilename: string|null) {
    if (!suggestedFilename) {
      suggestedFilename = 'file';
    }

    let response;
    try {
      response = await axios({
        url: this.baseUrl + url + this.debugPostfix,
        method: 'GET',
        headers: this.requestHeaders,
        responseType: 'blob',
        params,
      });
      // eslint-disable-next-line
    } catch (e: any) {
      const blob = new Blob([e.response.data]);

      const text = await blob.text();
      throw new Error(JSON.parse(text).error) ;
    }

    const blob = new Blob([response.data]);
    const fileURL = window.URL.createObjectURL(blob);
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    let fileName = suggestedFilename;
    if (response.headers["x-suggested-filename"]) {
      fileName = response.headers["x-suggested-filename"];
    }

    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);

    fileLink.click();
  }
}

export default {
  install(Vue: Vue): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let url = Vue.prototype.$backendUrl;
    if (window.location.href.indexOf('backend.dev.companysandwich.nl') > -1) {
      url = 'http://api.dev.companysandwich.nl/';
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Vue.prototype.$backendRequestService = new BackendRequestService(url);
  }
}
