import {Franchise} from "./Franchise";
import Invoice from "./Invoice";
import DateObject from "@/service/DateObject";

export default class DefaultState{
  loading = true;
  managedFranchises: Franchise[] = [];
  paymentStatuses: string[] = [];
  paymentMethods: string[] = [];
  invoices: Invoice[] = [];
  totalInvoices = 0;
  fromDate: DateObject|null = null;
  toDate: DateObject|null = null;
  pickedFranchises: string[] = [];
  pickedPaymentStatuses: string[] = [];
  pickedPaymentMethods: string[] = [];
  searchQuery = '';
  currentPage = 1;
  amountPerPage = 50;
}
