import {Franchise} from "./Franchise";
import DateObject from "@/service/DateObject";

export class OrdersForDate {
  date: string;
  revenue: number;

  constructor(date: string, revenue: number) {
    this.date = date;
    this.revenue = revenue;
  }
}


export default class DefaultState {
  loading = true;
  franchises: Franchise[] = [];
  pickedFranchise = 'all';
  startDate: DateObject|null = null;
  endDate: DateObject|null = null;
  orders: OrdersForDate[] = [];
}
