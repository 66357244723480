
import Vue from 'vue';
import Login from '@/components/Login/Login.vue';
import ResetPassword from '@/components/ResetPassword/Page/ResetPassword.vue';
import TopBar from '@/components/Generic/TopBar.vue';
import MainNavigation from '@/components/Generic/MainNavigation.vue';
import Notifications from "./components/Notification/Notifications.vue";
import LoginSocial from "@/components/LoginSocial/LoginSocial.vue";

export default Vue.extend({
  name: 'App',
  components: {LoginSocial, Login, Notifications, TopBar, MainNavigation, ResetPassword},

  mounted() {
    this.$store.dispatch('setUserToken');
    this.$store.dispatch('fetchUser');
  },

  data: () => ({

  }),
});
