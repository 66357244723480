import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"switch-to-franchise",attrs:{"elevation":"0"}},[_c(VImg,[_c(VIcon,{staticClass:"icon"},[_vm._v("mdi-account-arrow-right")])],1),_c(VCardTitle,{staticClass:"card-title"},[_vm._v(" Wissel van franchise ")]),_c(VCardText,{staticClass:"franchise-list__link"},_vm._l((_vm.franchises),function(franchise){return _c(VBtn,{key:franchise.id,attrs:{"elevation":"2","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('switchToFranchise', franchise.id)}}},[_vm._v(_vm._s(franchise.name))])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }