import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c(VCard,{staticClass:"debitors",attrs:{"href":_vm.$router.resolve({path: `/invoice/debitors`}).href,"elevation":"0"}},[(_vm.total > 0)?[_c(VImg,[_c(VIcon,{staticClass:"icon",attrs:{"color":"red"}},[_vm._v("mdi-cash-multiple")])],1),_c(VCardTitle,{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$n(_vm.total, 'currency'))+" ")]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.count)+" facturen")]),_c(VCardText,[_vm._v("Facturen die actie vereisen")])]:[_c(VImg,[_c(VIcon,{staticClass:"icon",attrs:{"color":"success"}},[_vm._v("mdi-check")])],1),_c(VCardTitle,{staticClass:"card-title"},[_vm._v(" Geen debiteuren ")]),_c(VCardSubtitle,[_vm._v(" ")]),_c(VCardText,[_vm._v(" ")])]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }