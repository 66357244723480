import {CustomerInfo, CustomProduct, OrderData, PickedAddresses} from "@/components/Order/ValueObjects/index";
import DateObject from "@/service/DateObject";
import {PickedProduct} from "@/components/Order/ValueObjects/PickedProduct";

export default class DefaultState {
  orderId: string|null = null;
  loading = true;
  franchise: {id: string}|null = null;
  orderData: OrderData = {
    deliveryDate: DateObject.now().addDays(1).setTime(12, 0),
    costCenter: '',
    remark: '',
    paymentMethod: 'Factuur',
    deliveryCosts: 0,
  };
  customerInfo: CustomerInfo = {
    id: null,
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    invoiceEmail: '',
    addresses: [],
  };
  addresses: PickedAddresses = {
    deliveryAddress: null,
    invoiceAddress: null,
  };
  pickedProducts: PickedProduct[] = [];
  paymentMethods: Record<string, unknown>[] = [];
  customProducts: CustomProduct[] = [];
  franchiseProducts: Record<string, unknown>[] = [];
  franchiseProductoptions: Record<string, unknown>[] = [];
  franchiseCategories: Record<string, unknown>[] = [];
  savingOrder = false;
  sendOrderConfirmation = false;
}
