import DateObject from "@/service/DateObject";

export default class Invoice {
  id: string;
  contactName: string;
  company: string;
  invoiceDate: DateObject;
  invoiceNumber: string;
  lastReminderSent: DateObject|null
  orderNumber: string;
  paymentDeadline: DateObject|null;
  paymentDeadlineInDays: number
  paymentMethod: string
  remindersSent: number;
  status: string;
  type: string;
  total: number;

  constructor(id: string, contactName: string, company: string, invoiceDate: DateObject, invoiceNumber: string, lastReminderSent: DateObject | null, orderNumber: string, paymentDeadline: DateObject|null, paymentDeadlineInDays: number, paymentMethod: string, remindersSent: number, status: string, type: string, total: number) {
    this.id = id;
    this.contactName = contactName;
    this.company = company;
    this.invoiceDate = invoiceDate;
    this.invoiceNumber = invoiceNumber;
    this.lastReminderSent = lastReminderSent;
    this.orderNumber = orderNumber;
    this.paymentDeadline = paymentDeadline;
    this.paymentDeadlineInDays = paymentDeadlineInDays;
    this.paymentMethod = paymentMethod;
    this.remindersSent = remindersSent;
    this.status = status;
    this.type = type;
    this.total = total;
  }
}
