import DefaultState, {OrdersForDate} from "../ValueObject/DefaultState";
import {StoreContext} from "../ValueObject/StoreContext";
import {Franchise} from "../ValueObject/Franchise";
import Vue from "vue";
import {AxiosError} from "axios";
import DateObject from "@/service/DateObject";

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    setFranchises(state: DefaultState, franchises: Franchise[]): void {
      state.franchises = franchises;
    },
    setPickedFranchise(state: DefaultState, franchise: string): void {
      state.pickedFranchise = franchise;
    },
    setStartDate(state: DefaultState, date: DateObject): void {
      state.startDate = date;
    },
    setEndDate(state: DefaultState, date: DateObject): void {
      state.endDate = date;
    },
    setOrders(state: DefaultState, orders: OrdersForDate[]): void {
      state.orders = orders;
    },
  },
  actions: {
    fetchFranchises(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get('franchises').then((data: {franchises: {id: string, name: string}[]}) => {
        const franchises = data.franchises;
        franchises.unshift({id: 'all', name: 'Alle'})
        context.commit('setFranchises', franchises);
      })
    },
    downloadCustomers(context: StoreContext): void {
      const params = {
        "start-date": context.getters.startDate !== null ? context.getters.startDate.toSystemDate() : null,
        "end-date": context.getters.endDate !== null ? context.getters.endDate.toSystemDate() : null,
      };
      Vue.prototype.$backendRequestService.download(`admin/export-customers/${context.getters.pickedFranchise}`, params)
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    downloadOrders(context: StoreContext): void {
      const params = {
        "start-date": context.getters.startDate !== null ? context.getters.startDate.toSystemDate() : null,
        "end-date": context.getters.endDate !== null ? context.getters.endDate.toSystemDate() : null,
      };
      Vue.prototype.$backendRequestService.download(`admin/export-orders/${context.getters.pickedFranchise}`, params)
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    fetchOrderStatistics(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get(`orders-per-payment-method-per-day`)
        .then((data: {orders: OrdersForDate[]}) => {
          context.commit('setOrders', data.orders);
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    pickedFranchise(state: DefaultState): string|null {
      return state.pickedFranchise;
    },
    startDate(state: DefaultState): DateObject|null {
      return state.startDate;
    },
    endDate(state: DefaultState): DateObject|null {
      return state.endDate;
    },
    franchises(state: DefaultState): {title: string, value: string}[] {
      return state.franchises.map(
        (franchise: Franchise): {title: string, value: string} => {
          return {title: franchise.name, value: franchise.id}
        }
      );
    },
    orders(state: DefaultState): any {
      return state.orders;
    }
  }
}
