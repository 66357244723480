import DefaultState from "../ValueObject/DefaultState";
import Invoice from "../ValueObject/Invoice";
import {Franchise} from "../ValueObject/Franchise";
import {StoreContext} from "../ValueObject/StoreContext";
import {InvoicesResponse} from "../ValueObject/InvoicesResponse";
import DateObject from "@/service/DateObject";
import {AxiosError} from "axios";
import Vue from "vue";

interface LooseObject {
  [key: string]: number|string
}

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    setLoading(state: DefaultState, loading: boolean): void {
      state.loading = loading;
    },
    setManagedFranchises(state: DefaultState, managedFranchises: Franchise[]): void {
      state.managedFranchises = managedFranchises;
    },
    setPaymentStatuses(state: DefaultState, paymentStatuses: string[]): void {
      state.paymentStatuses = paymentStatuses;
    },
    setPaymentMethods(state: DefaultState, paymentMethods: string[]): void {
      state.paymentMethods = paymentMethods;
    },
    setInvoices(state: DefaultState, invoices: Invoice[]): void {
      state.invoices = invoices;
    },
    setTotalInvoices(state: DefaultState, totalInvoices: number): void {
      state.totalInvoices = totalInvoices;
    },
    setFromDate(state: DefaultState, fromDate: DateObject|null): void {
      state.fromDate = fromDate;
    },
    setToDate(state: DefaultState, toDate: DateObject|null): void {
      state.toDate = toDate;
    },
    setPickedFranchises(state: DefaultState, pickedFranchises: string[]): void {
      state.pickedFranchises = pickedFranchises;
    },
    setPickedPaymentStatuses(state: DefaultState, pickedPaymentStatuses: string[]): void {
      state.pickedPaymentStatuses = pickedPaymentStatuses;
    },
    setPickedPaymentMethods(state: DefaultState, pickedPaymentMethods: string[]): void {
      state.pickedPaymentMethods = pickedPaymentMethods;
    },
    setSearchQuery(state: DefaultState, searchQuery: string): void {
      state.searchQuery = searchQuery;
    },
    setCurrentPage(state: DefaultState, currentPage: number): void {
      state.currentPage = currentPage;
    },
    setAmountPerPage(state: DefaultState, amountPerPage: number): void {
      state.amountPerPage = amountPerPage;
    },
  },
  actions: {
    fetchInvoices(context: StoreContext): void {
      context.commit('setLoading', true);
      const params = {
        page: context.state.currentPage,
        amountPerPage: context.state.amountPerPage,
        query: context.state.searchQuery,
        paymentStatuses: context.state.pickedPaymentStatuses,
        paymentMethods: context.state.pickedPaymentMethods,
        selectedFranchises: context.state.pickedFranchises,
        fromDate: context.state.fromDate !== null ? context.state.fromDate.toSystemDate() : null,
        toDate: context.state.toDate !== null ? context.state.toDate.toSystemDate() : null,
      };

      Vue.prototype.$backendRequestService.get('admin-invoices', params).then((data: InvoicesResponse) => {
        const invoices = data.invoices.map(invoice => {
          return new Invoice(
            invoice.id,
            invoice.contactName,
            invoice.company,
            DateObject.fromDateString(invoice.invoiceDate),
            invoice.invoiceNumber,
            invoice.lastReminderSent !== null ? DateObject.fromDateString(invoice.lastReminderSent) : null,
            invoice.orderNumber,
            invoice.paymentDeadline !== null ? DateObject.fromDateString(invoice.paymentDeadline) : null,
            invoice.paymentDeadlineInDays,
            invoice.paymentMethod,
            invoice.remindersSent,
            invoice.status,
            invoice.type,
            invoice.total,
          );
        });

        context.commit('setInvoices', invoices);
        context.commit('setTotalInvoices', data.totalInvoices);
        context.commit('setManagedFranchises', data.managedFranchises);
        context.commit('setPaymentStatuses', data.paymentStatuses);
        context.commit('setPaymentMethods', data.paymentMethods);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => {
        context.commit('setLoading', false);
        context.dispatch('updateUrl');
      });
    },
    refreshInvoices(context: StoreContext): void {
      const params = {
        page: context.state.currentPage,
        amountPerPage: context.state.amountPerPage,
        query: context.state.searchQuery,
        paymentStatuses: context.state.pickedPaymentStatuses,
        paymentMethods: context.state.pickedPaymentMethods,
        selectedFranchises: context.state.pickedFranchises,
        fromDate: context.state.fromDate !== null ? context.state.fromDate.toSystemDate() : null,
        toDate: context.state.toDate !== null ? context.state.toDate.toSystemDate() : null,
      };

      Vue.prototype.$backendRequestService.get('admin-invoices', params).then((data: InvoicesResponse) => {
        const invoices = data.invoices.map(invoice => {
          return new Invoice(
            invoice.id,
            invoice.contactName,
            invoice.company,
            DateObject.fromDateString(invoice.invoiceDate),
            invoice.invoiceNumber,
            invoice.lastReminderSent !== null ? DateObject.fromDateString(invoice.lastReminderSent) : null,
            invoice.orderNumber,
            invoice.paymentDeadline !== null ? DateObject.fromDateString(invoice.paymentDeadline) : null,
            invoice.paymentDeadlineInDays,
            invoice.paymentMethod,
            invoice.remindersSent,
            invoice.status,
            invoice.type,
            invoice.total,
          );
        });

        context.commit('setInvoices', invoices);
        context.commit('setTotalInvoices', data.totalInvoices);
        context.commit('setManagedFranchises', data.managedFranchises);
        context.commit('setPaymentStatuses', data.paymentStatuses);
        context.commit('setPaymentMethods', data.paymentMethods);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    exportInvoices(context: StoreContext): void {
      const params = {
        page: context.state.currentPage,
        amountPerPage: context.state.amountPerPage,
        query: context.state.searchQuery,
        paymentStatuses: context.state.pickedPaymentStatuses,
        paymentMethods: context.state.pickedPaymentMethods,
        selectedFranchises: context.state.pickedFranchises,
        fromDate: context.state.fromDate !== null ? context.state.fromDate.toSystemDate() : null,
        toDate: context.state.toDate !== null ? context.state.toDate.toSystemDate() : null,
      };
      Vue.prototype.$backendRequestService.download('admin-invoices/export', params).catch((error: AxiosError) => context.commit('addError', error));
    },
    updateUrl(context: StoreContext): void {
      const params: LooseObject = {
        page: context.state.currentPage,
        amountPerPage: context.state.amountPerPage,
      };

      if (context.state.searchQuery) {
        params.query = context.state.searchQuery;
      }

      const esc = encodeURIComponent;
      const query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
      window.history.pushState(
        {},
        'Invoices',
        '/invoice?' + query
      );
    },
    clearAllFilters(context: StoreContext): void {
      context.commit('setCurrentPage', 1);
      context.commit('setSearchQuery', '');
      context.commit('setPickedFranchises', []);
      context.commit('setPickedPaymentMethods', []);
      context.commit('setPickedPaymentStatuses', []);
      context.commit('setFromDate', null);
      context.commit('setToDate', null);
      context.dispatch('fetchInvoices');
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    managedFranchises(state: DefaultState): Franchise[] {
      return state.managedFranchises;
    },
    paymentMethods(state: DefaultState): string[] {
      return state.paymentMethods;
    },
    paymentStatuses(state: DefaultState): string[] {
      return state.paymentStatuses;
    },
    invoices(state: DefaultState): Invoice[] {
      return state.invoices;
    },
    totalInvoices(state: DefaultState): number {
      return state.totalInvoices;
    },
    fromDate(state: DefaultState): DateObject|null {
      return state.fromDate;
    },
    toDate(state: DefaultState): DateObject|null {
      return state.toDate;
    },
    pickedFranchises(state: DefaultState): string[] {
      return state.pickedFranchises;
    },
    pickedPaymentStatuses(state: DefaultState): string[] {
      return state.pickedPaymentStatuses;
    },
    pickedPaymentMethods(state: DefaultState): string[] {
      return state.pickedPaymentMethods;
    },
    searchQuery(state: DefaultState): string {
      return state.searchQuery;
    },
    currentPage(state: DefaultState): number {
      return state.currentPage;
    },
    amountPerPage(state: DefaultState): number {
      return state.amountPerPage;
    },
  }
}
