<template>
  <v-main>
    <v-container fluid class="reset-password">
      <v-row align-content="center">
        <request-reset-password-mail-form />
        <password-requested-message />
        <fill-in-new-password />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue';
import PasswordRequestedMessage from "@/components/ResetPassword/Organism/PasswordRequestedMessage";
import RequestResetPasswordMailForm from "@/components/ResetPassword/Organism/RequestResetPasswordMailForm";
import FillInNewPassword from "@/components/ResetPassword/Organism/FillInNewPassword";

export default Vue.extend({
  name: "ResetPassword",
  components: {PasswordRequestedMessage, RequestResetPasswordMailForm, FillInNewPassword},
  mounted() {
    if (this.$route.query.token) {
      this.$store.commit('passwordReset/setToken', this.$route.query.token);
    }
  }
})
</script>

<style scoped>
.reset-password {
  min-height: 100vh;
  padding-top: 20vh;
  background-color: #d2d6de;
}
</style>
