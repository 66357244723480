<template>
  <v-card :href="$router.resolve({path: `/order/to-merge-invoice`}).href" elevation="0" class="total-invoice" v-if="!loading">
    <template v-if="total > 0">
      <v-img>
        <v-icon class="icon">mdi-cart-plus</v-icon>
        <v-icon class="small-icon">mdi-arrow-right</v-icon>
        <v-icon class="icon">mdi-cash-multiple</v-icon>
      </v-img>
      <v-card-title class="card-title">
        {{ $n(total, 'currency') }}
      </v-card-title>
      <v-card-subtitle>{{ count }} bestellingen</v-card-subtitle>
      <v-card-text>Verzamelfacturatie</v-card-text>
    </template>
    <template v-else>
      <v-img>
        <v-icon class="icon" color="success">mdi-check</v-icon>
      </v-img>
      <v-card-title class="card-title">
        Alles gefactureerd!
      </v-card-title>
      <v-card-subtitle>Verzamelfacturen</v-card-subtitle>
      <v-card-text>&nbsp;</v-card-text>
    </template>
  </v-card>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "TotalToMergeInvoice",
  computed: {
    ...mapGetters({
      loading: 'toInvoice/loading',
      count: 'toInvoice/mergeInvoicesCount',
      total: 'toInvoice/mergeInvoicesTotal',
    })
  },
  mounted() {
    this.$store.dispatch('toInvoice/fetchData');
  }
})
</script>

<style scoped>
.total-invoice {
  padding: 1rem;
  text-align: center;
}

.card-title {
  text-align: center;
  justify-content: center;
}

.small-icon {
  font-size: 2rem;
}

.icon {
  font-size: 3rem;
}
</style>
