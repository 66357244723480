import {StoreContext} from "@/store/ValueObject/StoreContext";
import Vue from "vue";
import {AxiosError} from "axios/index";

class DebitorsDefaultState{
  loading = false;
  total = 0;
  count = 0;
}

export default {
  state: (): DebitorsDefaultState => <DebitorsDefaultState>(new DebitorsDefaultState()),
  mutations: {
    startedLoading(state: DebitorsDefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DebitorsDefaultState): void {
      state.loading = false;
    },
    setCount(state: DebitorsDefaultState, count: number): void {
      state.count = count;
    },
    setTotal(state: DebitorsDefaultState, total: number): void {
      state.total = total;
    },
  },
  actions: {
    fetchData(context: StoreContext): void {
      context.commit('startedLoading');

      Vue.prototype.$backendRequestService.get('debitors-dashboard').then((data: {count: number, total: number, mergeInvoicesCount: number, mergeInvoicesTotal: number}) => {
        context.commit('setCount', data.count);
        context.commit('setTotal', data.total);
      }).finally(() => context.commit('doneLoading'));
    },
  },
  getters: {
    loading(state: DebitorsDefaultState): boolean {
      return state.loading;
    },
    count(state: DebitorsDefaultState): number {
      return state.count;
    },
    total(state: DebitorsDefaultState): number {
      return state.total;
    },
  }
}
