import DefaultState from "../ValueObjects/DefaultState";
import {StoreContext} from "@/components/Category/ValueObjects/StoreContext";
import Vue from "vue";
import {ProductCategoryResponse} from "@/components/Category/ValueObjects/ProductCategoryResponse";
import {ProductCategorySaveResponse} from "@/components/Category/ValueObjects/ProductCategorySaveResponse";

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    setCategoryId(state: DefaultState, category: string|null): void {
      state.categoryId = category;
    },
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setTitle(state: DefaultState, title: string): void {
      state.title = title;
    },
    setUrlFriendlyName(state: DefaultState, urlFriendlyName: string): void {
      state.urlFriendlyName = urlFriendlyName;
    },
    setSeoTitle(state: DefaultState, seoTitle: string): void {
      state.seoTitle = seoTitle;
    },
    setMetaDescription(state: DefaultState, metaDescription: string): void {
      state.metaDescription = metaDescription;
    },
    setMetaKeywords(state: DefaultState, metaKeywords: string): void {
      state.metaKeywords = metaKeywords;
    },
    setDescription(state: DefaultState, description: string): void {
      state.description = description;
    },
    setCurrentImageUrl(state: DefaultState, url: string): void {
      state.currentImageUrl = url;
    },
    setFile(state: DefaultState, file: Blob): void {
      state.file = file;
    },
  },
  actions: {
    fetchCategory(context: StoreContext, categoryId: string): void {
      context.commit('setCategoryId', categoryId);
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get(`product-category/${categoryId}`).then((data: ProductCategoryResponse) => {
        context.commit('setTitle', data.category.title);
        context.commit('setUrlFriendlyName', data.category.urlFriendlyName);
        context.commit('setDescription', data.category.description);
        context.commit('setSeoTitle', data.category.seoTitle);
        context.commit('setMetaDescription', data.category.metaDescription);
        context.commit('setMetaKeywords', data.category.metaKeywords);
        context.commit('setCurrentImageUrl', data.category.currentImageUrl);
      }).catch(Vue.prototype.showError).finally(() => context.commit('doneLoading'));
    },
    saveCategory(context: StoreContext): void {
      let url = 'product-category/create';
      if (context.state.categoryId) {
        url = `product-category/${context.state.categoryId}`;
      }

      context.commit('startLoading');
      const formData = new FormData();
      if (context.state.file) {
        formData.append("category-image", context.state.file);
      }
      formData.append('title', context.state.title);
      formData.append('urlFriendlyName', context.state.urlFriendlyName);
      formData.append('description', context.state.description);
      formData.append('seoTitle', context.state.seoTitle);
      formData.append('metaDescription', context.state.metaDescription);
      formData.append('metaKeywords', context.state.metaKeywords);

      Vue.prototype.$backendRequestService
        .upload(url, formData)
        .then((data: ProductCategorySaveResponse) => {
          if (!context.state.categoryId) {
            window.location.href = Vue.prototype.$baseUrl + 'ProductCategory/AddEdit/' + data.categoryId;
          }
        })
        .catch(Vue.prototype.showError)
        .finally(() => context.commit('doneLoading'));
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    title(state: DefaultState): string {
      return state.title;
    },
    seoTitle(state: DefaultState): string {
      return state.seoTitle;
    },
    metaKeywords(state: DefaultState): string {
      return state.metaKeywords;
    },
    metaDescription(state: DefaultState): string {
      return state.metaDescription;
    },
    urlFriendlyName(state: DefaultState): string {
      return state.urlFriendlyName;
    },
    description(state: DefaultState): string {
      return state.description;
    },
    file(state: DefaultState): string|Blob|null {
      return state.file;
    },
    currentImageUrl(state: DefaultState): string|null {
      return state.currentImageUrl;
    },
  }
}
