
import Statistics from "@/components/Dashboard/Statistics.vue";
import SwitchToFranchise from "@/components/Dashboard/Organism/SwitchToFranchise.vue";
import OrdersTomorrow from "@/components/Dashboard/Organism/OrdersTomorrow.vue";
import TotalToInvoice from "@/components/Dashboard/Organism/TotalToInvoice.vue";
import TotalToMergeInvoice from "@/components/Dashboard/Organism/TotalToMergeInvoice.vue";
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";
import Loader from "@/components/Generic/Loader.vue";
import Debitors from "@/components/Dashboard/Organism/Debitors.vue";
import Transactions from "@/components/Dashboard/Organism/Transactions.vue";
import KitchenList from "@/components/Dashboard/Organism/KitchenList.vue";

export default Vue.extend({
  name: "Dashboard",
  components: {
    Statistics,
    SwitchToFranchise,
    Loader,
    OrdersTomorrow,
    TotalToInvoice,
    TotalToMergeInvoice,
    Debitors,
    Transactions,
    KitchenList,
  },
  mounted() {
    this.fetchDashboardData();
    this.fetchStatistics();
  },
  computed: {
    ...mapGetters({
      loading: 'dashboard/loading',
      franchises: 'dashboard/franchises',
    })
  },
  methods: {
    ...mapActions({
      fetchDashboardData: 'dashboard/fetchDashboardData',
      fetchStatistics: 'dashboard/fetchStatistics',
    }),
  }
});
