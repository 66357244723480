import {
  OrderData,
  Address,
  CustomerInfo,
  PickedAddresses,
  CustomProduct,
  OrderResponseDefinition,
  ProductsResponseDefinition
} from "../ValueObjects";
import DateObject from "@/service/DateObject";
import Vue from "vue";
import router from "@/router";
import {AxiosError} from "axios";
import {PickedProduct} from "@/components/Order/ValueObjects/PickedProduct";
import DefaultState from "@/components/Order/ValueObjects/DefaultState";
import {StoreContext} from "@/components/Order/ValueObjects/StoreContext";

interface CustomerResponse {
  customer: CustomerInfo;
  paymentMethods: Record<string, unknown>[];
}

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    clearCustomer(state: DefaultState): void {
      state.customerInfo = {
        id: null,
        firstname: '',
        lastname: '',
        company: '',
        email: '',
        invoiceEmail: '',
        addresses: [],
      };
    },
    changeOrderId(state: DefaultState, orderId: string): void {
      state.orderId = orderId;
    },
    updateFranchise(state: DefaultState, franchise: {id: string}): void {
      state.franchise = franchise;
    },
    updateOrderData(state: DefaultState, orderData: OrderData): void {
      state.orderData = orderData;
    },
    updateCustomerInfo(state: DefaultState, customerInfo: CustomerInfo): void {
      state.customerInfo = customerInfo;
    },
    updateAddresses(state: DefaultState, addresses: PickedAddresses): void {
      state.addresses = addresses;
    },
    updatePickedProducts(state: DefaultState, pickedProducts: PickedProduct[]): void {
      state.pickedProducts = pickedProducts;
    },
    updatePaymentMethods(state: DefaultState, paymentMethods: Record<string, unknown>[]): void {
      state.paymentMethods = paymentMethods;
    },
    updateCustomProducts(state: DefaultState, customProducts: CustomProduct[]): void {
      state.customProducts = customProducts;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    updateDeliveryAddress(state: DefaultState, addressId: string): void {
      state.addresses.deliveryAddress = addressId;
    },
    updateInvoiceAddress(state: DefaultState, addressId: string): void {
      state.addresses.invoiceAddress = addressId;
    },
    removeInvoiceAddress(state: DefaultState): void {
      state.addresses.invoiceAddress = null;
    },
    addRegularProduct(state: DefaultState, product: PickedProduct): void {
      state.pickedProducts.push(product);
    },
    removeRegularProduct(state: DefaultState, regularProductIndex: number): void {
      state.pickedProducts.splice(regularProductIndex, 1);
    },
    updateFranchiseProducts(state: DefaultState, franchiseProducts: Record<string, unknown>[]): void {
      state.franchiseProducts = franchiseProducts;
    },
    updateFranchiseProductoptions(state: DefaultState, franchiseProductoptions: Record<string, unknown>[]): void {
      state.franchiseProductoptions = franchiseProductoptions;
    },
    updateFranchiseCategories(state: DefaultState, franchiseCategories: Record<string, unknown>[]): void {
      state.franchiseCategories = franchiseCategories;
    },
    addCustomProduct(state: DefaultState): void {
      state.customProducts.push({
        uniqueId: Math.random().toString(36).substring(7),
        title: '',
        amount: 1,
        price: 0,
        tax: 9,
        total: 0,
      });
    },
    removeCustomProduct(state: DefaultState, customProductIndex: number): void {
      state.customProducts.splice(customProductIndex, 1);
    },
    startSavingOrder(state: DefaultState): void {
      state.savingOrder = true;
    },
    orderSaved(state: DefaultState): void {
      state.savingOrder = false;
    },
    setSendOrderConfirmation(state: DefaultState, sendConfirmation: boolean): void {
      state.sendOrderConfirmation = sendConfirmation;
    },
    setDeliveryTime(state: DefaultState, time: {hours: number, minutes: number}): void {
      state.orderData.deliveryDate = state.orderData.deliveryDate.setTime(time.hours, time.minutes);
    },
    setDeliveryDate(state: DefaultState, date: {year: number, month: number, day: number}): void {
      state.orderData.deliveryDate = state.orderData.deliveryDate.setDate(date.year, date.month, date.day);
    },
    resetOrder(state: DefaultState): void {
      state.orderId = null;
      state.franchise = null;
      state.orderData = {
        deliveryDate: DateObject.now().addDays(1).setTime(12, 0),
        costCenter: '',
        remark: '',
        paymentMethod: 'Factuur',
        deliveryCosts: 0,
      };
      state.customerInfo = {
        id: null,
        firstname: '',
        lastname: '',
        company: '',
        email: '',
        invoiceEmail: '',
        addresses: [],
      };
      state.addresses = {
        deliveryAddress: null,
        invoiceAddress: null,
      };
      state.pickedProducts = [];
      state.paymentMethods = [];
      state.customProducts = [];
      state.franchiseProducts = [];
      state.franchiseProductoptions = [];
      state.franchiseCategories = [];
      state.sendOrderConfirmation = false;
    }
  },
  actions: {
    fetchOrderData(context: StoreContext, orderId: string): void {
      Vue.prototype.$backendRequestService.get('admin-orders/order/' + orderId, {})
        .then((data: OrderResponseDefinition) => {
          if (data.orderData.deliveryDate) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data.orderData.deliveryDate = DateObject.fromDateString(data.orderData.deliveryDate);
          }

          context.commit('changeOrderId', orderId);
          context.commit('updateFranchise', data.franchise);
          context.commit('updateOrderData', data.orderData);
          context.commit('updateCustomerInfo', data.customer);
          context.commit('updateAddresses', data.addresses);
          context.commit('updatePickedProducts', data.pickedProducts);
          context.commit('updatePaymentMethods', data.paymentMethods);
          context.commit('updateCustomProducts', data.customProducts);
          context.dispatch('fetchFranchiseProducts');
          context.dispatch('calculateTotals');
        })
        .finally(() => {
          context.commit('doneLoading');
        });
    },
    fetchFranchiseInfo(context: StoreContext): void {
      if (context.state.orderId !== null) {
        return;
      }

      Vue.prototype.$backendRequestService.post('get-franchise-by-postcode', {postcode: context.getters.deliveryAddress.postcode}).then((data: {franchise: {id: string}}) => {
        context.commit('updateFranchise', data.franchise);
        context.dispatch('fetchFranchiseProducts');
      }).catch(() => {
        context.commit('addNotification', 'Geen vestiging bij dit bezorgadres gevonden', {root: true});
      });
    },
    fetchFranchiseProducts(context: StoreContext): void {
      if (context.state.franchise === null) {
        return;
      }

      Vue.prototype.$backendRequestService.get(`admin/products/${context.state.franchise.id}`).then((data: ProductsResponseDefinition) => {
        context.commit('updateFranchiseProducts', data.products);
        context.commit('updateFranchiseProductoptions', data.productOptions);
        context.commit('updateFranchiseCategories', data.productCategories);
      }).catch((error: AxiosError) => context.commit('addError', error));
    },
    saveOrder(context: StoreContext): void {
      if (context.state.pickedProducts.length === 0 && context.state.customProducts.length === 0) {
        return context.commit('addNotification', 'Bestelling heeft minimaal 1 product nodig', {root: true});
      }

      if (!context.state.franchise) {
        return context.commit('addWarning', 'Franchise is niet gevonden');
      }
      context.commit('startSavingOrder');
      const data = {
        customerId: context.state.customerInfo.id,
        franchiseId: context.state.franchise.id,
        orderData: {
          deliveryDate: context.state.orderData.deliveryDate.toSystemDateTime(),
          costCenter: context.state.orderData.costCenter,
          remark: context.state.orderData.remark,
          paymentMethod: context.state.orderData.paymentMethod,
          deliveryCosts: context.state.orderData.deliveryCosts,
        },
        addresses: context.state.addresses,
        pickedProducts: context.state.pickedProducts,
        customProducts: context.state.customProducts,
        sendOrderConfirmation: context.state.sendOrderConfirmation,
      }

      if (context.state.orderId) {
        return Vue.prototype.$backendRequestService.post(`admin-orders/update/${context.state.orderId}`, data).then(() => {
          context.commit('setSendOrderConfirmation', false);
          return context.commit('addSuccessMessage', 'Bestelling is opgeslagen', {root: true});
        }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => {
          context.commit('orderSaved');
        });
      }

      Vue.prototype.$backendRequestService.post('admin-orders/create', data).then((data: {id: string}) => {
        router.push(`/order/${data.id}`);
        context.commit('addSuccessMessage', 'Bestelling is opgeslagen', {root: true});
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => {
        context.commit('orderSaved');
      });
    },
    calculateTotals(context: StoreContext): void {
      context.state.customProducts.map((product: CustomProduct) => {
        product.total = product.amount * parseFloat(product.price.toString().replace(',', '.'));
      });
    },
    fetchCustomer(context: StoreContext, customerId: string): void {
      Vue.prototype.$backendRequestService.get(`customer/${customerId}`).then((data: CustomerResponse) => {
        context.commit('updateCustomerInfo', data.customer);
        context.commit('updatePaymentMethods', data.paymentMethods);
        data.customer.addresses.map((address: Address)  => {
          if (address.default) {
            context.commit('updateDeliveryAddress', address.id);
            context.dispatch('fetchFranchiseInfo');
          }

          if (address.billingAddress) {
            context.commit('updateInvoiceAddress', address.id);
          }
        });
      }).catch((error: AxiosError) => context.commit('addError', error)).finally(() => {
        context.commit('doneLoading');
      });
    },
    downloadPDF(context: StoreContext, orderId: string): void {
      Vue.prototype.$backendRequestService.download(`admin-order/${orderId}`).catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    }
  },
  getters: {
    customerId(state: DefaultState): string|null {
      return state.customerInfo.id;
    },
    savingOrder(state: DefaultState): boolean {
      return state.savingOrder;
    },
    orderId(state: DefaultState): string|null {
      return state.orderId;
    },
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    franchise(state: DefaultState): {id: string}|null {
      return state.franchise;
    },
    orderData(state: DefaultState): OrderData {
      return state.orderData;
    },
    customerInfo(state: DefaultState): CustomerInfo {
      return state.customerInfo;
    },
    addresses(state: DefaultState): PickedAddresses {
      return state.addresses;
    },
    pickedProducts(state: DefaultState): PickedProduct[] {
      return state.pickedProducts;
    },
    paymentMethods(state: DefaultState): Record<string, unknown>[] {
      return state.paymentMethods;
    },
    customProducts(state: DefaultState): CustomProduct[] {
      return state.customProducts;
    },
    deliveryAddress(state: DefaultState): Address|null {
      for (let i = 0; i < state.customerInfo.addresses.length; i++) {
        if (state.customerInfo.addresses[i].id === state.addresses.deliveryAddress) {
          return state.customerInfo.addresses[i];
        }
      }

      return null;
    },
    invoiceAddress(state: DefaultState): Address|null {
      for (let i = 0; i < state.customerInfo.addresses.length; i++) {
        if (state.customerInfo.addresses[i].id === state.addresses.invoiceAddress) {
          return state.customerInfo.addresses[i];
        }
      }

      return null;
    },
    customerDeliveryAddresses(state: DefaultState): Address[] {
      return state.customerInfo.addresses;
    },
    customerInvoiceAddresses(state: DefaultState): Address[] {
      return state.customerInfo.addresses.filter(address => address.billingAddress);
    },
    franchiseProducts(state: DefaultState): Record<string, unknown>[] {
      return state.franchiseProducts;
    },
    franchiseProductoptions(state: DefaultState): Record<string, unknown>[] {
      return state.franchiseProductoptions;
    },
    franchiseCategories(state: DefaultState): Record<string, unknown>[] {
      return state.franchiseCategories;
    },
    sendOrderConfirmation(state: DefaultState): boolean {
      return state.sendOrderConfirmation;
    },
  }
}
