import Vue from "vue";
import DefaultState from "@/components/Postcodes/ValueObject/DefaultState";
import {PostcodesInterface} from "@/components/Postcodes/ValueObject/PostcodesInterface";
import {StoreContext} from "@/components/Postcodes/ValueObject/StoreContext";
import {AxiosError} from "axios";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setPostcodeList(state: DefaultState, data: PostcodesInterface): void {
      state.postcodeList = data;
    }
  },
  actions: {
    fetchPostcodes(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('post-codes/franchise-list').then((data: PostcodesInterface) => {
        context.commit('setPostcodeList', data);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .finally(() => context.commit('doneLoading'));
    },
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    postcodeList(state: DefaultState): PostcodesInterface {
      return state.postcodeList;
    },
  }
}
