import ToInvoiceDefaultState from "@/components/Dashboard/ValueObject/ToInvoiceDefaultState";
import {StoreContext} from "@/store/ValueObject/StoreContext";
import Vue from "vue";

export default {
  state: (): ToInvoiceDefaultState => <ToInvoiceDefaultState>(new ToInvoiceDefaultState()),
  mutations: {
    startedLoading(state: ToInvoiceDefaultState): void {
      state.loading = true;
    },
    doneLoading(state: ToInvoiceDefaultState): void {
      state.loading = false;
    },
    setCount(state: ToInvoiceDefaultState, count: number): void {
      state.count = count;
    },
    setTotal(state: ToInvoiceDefaultState, total: number): void {
      state.total = total;
    },
    setMergeInvoicesCount(state: ToInvoiceDefaultState, mergeInvoicesCount: number): void {
      state.mergeInvoicesCount = mergeInvoicesCount;
    },
    setMergeInvoicesTotal(state: ToInvoiceDefaultState, mergeInvoicesTotal: number): void {
      state.mergeInvoicesTotal = mergeInvoicesTotal;
    },
  },
  actions: {
    fetchData(context: StoreContext): void {
      context.commit('startedLoading');

      Vue.prototype.$backendRequestService.get('order-to-invoice').then((data: {count: number, total: number, mergeInvoicesCount: number, mergeInvoicesTotal: number}) => {
        context.commit('setCount', data.count);
        context.commit('setTotal', data.total);
        context.commit('setMergeInvoicesCount', data.mergeInvoicesCount);
        context.commit('setMergeInvoicesTotal', data.mergeInvoicesTotal);
      }).finally(() => context.commit('doneLoading'));
    },
  },
  getters: {
    loading(state: ToInvoiceDefaultState): boolean {
      return state.loading;
    },
    count(state: ToInvoiceDefaultState): number {
      return state.count;
    },
    total(state: ToInvoiceDefaultState): number {
      return state.total;
    },
    mergeInvoicesCount(state: ToInvoiceDefaultState): number {
      return state.mergeInvoicesCount;
    },
    mergeInvoicesTotal(state: ToInvoiceDefaultState): number {
      return state.mergeInvoicesTotal;
    },
  }
}
