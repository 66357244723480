<template>
  <div class="statistics">
    <h3>Statistieken</h3>
    <v-row>
      <v-col cols="12" lg="3">
        <v-select label="Jaar" :items="years" v-model="currentYear" />
      </v-col>
    </v-row>
    <template v-if="!loadingStatistics">
      <v-row no-gutters>
        <v-col cols="12" lg="3">
          <strong>Aantal bestellingen deze maand: </strong><br>
          {{ $n(currentMonth.totalOrders) }}
        </v-col>
        <v-col cols="12" lg="3">
          <strong>Bestellingen totaal deze maand: </strong><br>
          {{ $n(currentMonth.totalEuros, 'currency') }}
        </v-col>
      </v-row>
      <v-row class="charts">
        <v-col cols="12" lg="6">
          <h4>Aantallen</h4>
          <g-chart type="ColumnChart" :data="annualData.monthlyCount" :options="chartOptions"/>
        </v-col>
        <v-col cols="12" lg="6">
          <h4>Totalen</h4>
          <g-chart type="ColumnChart" :data="annualData.monthlyTotal" :options="chartOptions"/>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col cols="12">
          <h3>Jaarlijkse statistieken</h3>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" lg="3">
          <strong>Aantal bestellingen: </strong><br>
          {{ $n(annualData.orderCount) }}
        </v-col>
        <v-col cols="12" lg="3">
          <strong>Bestellingen totaal: </strong><br>
          {{ $n(annualData.orderTotal, 'currency') }}
        </v-col>
        <v-col cols="12" lg="3">
          <strong>Aantal facturen: </strong><br>
          {{ $n(annualData.invoiceCount) }}
        </v-col>
        <v-col cols="12" lg="3">
          <strong>Facturen totaal: </strong><br>
          {{ $n(annualData.invoiceTotal, 'currency') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="info-line">Alle totalen zijn exclusief BTW berekend.</p>
        </v-col>
      </v-row>
    </template>
    <loader v-else />
  </div>
</template>

<script>
import {GChart} from 'vue-google-charts';
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/Generic/Loader";
import Vue from "vue";

export default Vue.extend({
  name: "Statistics",
  components: {GChart, Loader},
  data() {
    return {
      chartOptions: {
        legend: {position: 'none'},
        chart: {
          title: 'Aantal',
          subtitle: '',
        },
        curveType: 'function',
        hAxis: {
          viewWindow: {
            min: 0
          },
        },
        vAxis: {
          title: 'Aantal',
          viewWindow: {
            min: 0
          },
        },
        series: {
          0: {
            color: '#00a65a',
            lineWidth: 2,
            opacity: 0.3
          },
          1: {
            color: '#263238',
            lineWidth: 2,
            opacity: 0.3
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentYearStore: 'dashboard/currentYear',
      months: 'dashboard/months',
      years: 'dashboard/years',
      totalOrders: 'dashboard/totalOrders',
      totalRevenue: 'dashboard/totalRevenue',
      deliveryData: 'dashboard/deliveryData',
      revenueData: 'dashboard/revenueData',
      loadingStatistics: 'dashboard/loadingStatistics',
      annualData: 'dashboard/annualData',
      currentMonth: 'dashboard/currentMonth',
    }),
    currentYear: {
      get() {
        return this.currentYearStore;
      },
      set(value) {
        this.$store.commit('dashboard/setCurrentYear', value);
        this.fetchStatistics();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchStatistics: 'dashboard/fetchStatistics'
    })
  }
})
</script>

<style scoped>
.statistics {
  padding: 1.5rem;
  background: #fff;
}

.charts {
  min-height: 270px;
}

.statistics__choices {
  max-width: 200px;
}

.graph-header {
  margin: 0;
}

.info-line {
  font-weight: 300;
  font-style: italic;
  margin: 0;
  font-size: .9em;
}
</style>
