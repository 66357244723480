<template>
  <v-col align="center" v-if="token">
    <v-form>
      <v-card width="400">
        <v-card-title>
          <v-col align-self="center">
            <v-img src="@/assets/logo.png" alt="Company Sandwich logo" max-width="125"/>
          </v-col>
        </v-card-title>
        <v-card-text>Vul hieronder een nieuw en sterk wachtwoord in.</v-card-text>
        <v-card-text>
          <v-text-field type="password" id="_password" label="Wachtwoord" v-model="_password" hide-details />
          <v-text-field type="password" id="_rePassword" label="Wachtwoord opnieuw" v-model="_rePassword" hide-details />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" type="submit" @click.prevent="_saveNewPassword" small>Opslaan</v-btn>
          <v-btn type="submit" @click.prevent="$router.push('/')" small>Terug naar inloggen</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-col>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "FillInNewPassword",
  computed: {
    ...mapGetters('passwordReset', ['password', 'rePassword', 'token']),
    _password: {
      get() {
        return this.password;
      },
      set(value) {
        this.$store.commit('passwordReset/setPassword', value);
      },
    },
    _rePassword: {
      get() {
        return this.rePassword;
      },
      set(value) {
        this.$store.commit('passwordReset/setRePassword', value);
      },
    },
  },
  methods: {
    ...mapActions('passwordReset', ['saveNewPassword']),
    _saveNewPassword() {
      this.saveNewPassword()
      .then(() => {
        this.$router.push('/');
      });
    }
  }
})
</script>
