import DefaultState from "../ValueObject/DefaultState";
import {StoreContext} from "@/components/OpeningTimes/ValueObject/StoreContext";
import Vue from "vue";
import {AxiosError} from "axios";
import {OpeningTimes} from "@/components/OpeningTimes/ValueObject/OpeningTimes";
import {Day} from "@/components/OpeningTimes/ValueObject/Day";
import DateObject from "@/service/DateObject";

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setAddClosedDateModal(state: DefaultState, status: boolean): void {
      state.addClosedDateModal = status;
    },
    setOpeningTimes(state: DefaultState, franchiseOpeningTimes: OpeningTimes[]): void {
      state.franchiseOpeningTimes = franchiseOpeningTimes;
    },
    setActiveFranchise(state: DefaultState, activeFranchise: string): void {
      state.activeFranchise = activeFranchise;
    },
    setAddClosedDateError(state: DefaultState, error: null|string): void {
      state.addingClosedDateError = error;
    },
    setDay(state: DefaultState, data: {franchise: string, day: Day}): void {
      for (const i in state.franchiseOpeningTimes) {
        if (state.franchiseOpeningTimes[i].id !== data.franchise) {
          continue;
        }

        for (const j in state.franchiseOpeningTimes[i].times) {
          if (state.franchiseOpeningTimes[i].times[j].dayOfWeek !== data.day.dayOfWeek) {
            continue;
          }

          Object.assign(state.franchiseOpeningTimes[i].times[j], data.day);
          return;
        }
      }
    },
  },
  actions: {
    fetchData(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('admin/opening-times')
        .then((data: Record<string, unknown>) => {
          context.commit('setOpeningTimes', data.openingTimes);
          context.commit('setActiveFranchise', data.primaryFranchise);
        })
        .catch((error: AxiosError) => context.commit('addError', error))
        .finally(() => {
          context.commit('doneLoading');
        });
    },
    save(context: StoreContext, franchise: string): void {
      context.state.franchiseOpeningTimes.map((openingTimes: OpeningTimes) => {
        if (openingTimes.id === franchise) {
          Vue.prototype.$backendRequestService.post(`admin/opening-times/${franchise}/save`, openingTimes)
            .then(() => {
              context.commit('addSuccessMessage', `Openingstijden succesvol opgeslagen voor vestiging ${openingTimes.name}`, {root: true})
            });
        }
      })
    },
    addClosedDate(context: StoreContext, closedDate: {franchise: string, date: DateObject|null, message: string}): void {
      const {franchise, date, message} = closedDate;
      context.commit('setAddClosedDateError', null);
      if (message.length === 0) {
        return context.commit('setAddClosedDateError', 'Er is geen bericht toegevoegd');
      }

      if (date === null) {
        return context.commit('setAddClosedDateError', 'Geen datum gekozen');
      }

      const params = {
        franchise: franchise,
        date: date.toSystemDate(),
        message
      };

      return Vue.prototype.$backendRequestService.post(`admin/closed-date/add`, params)
        .then(() => {
          context.dispatch('fetchData');
          context.commit('addSuccessMessage', `Datum toegevoegd`, {root: true});
          context.commit('setAddClosedDateModal', false);
        })
        .catch((error: AxiosError) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          context.commit('setAddClosedDateError', error.response.data.error);
        });
    },
    removeClosedDate(context: StoreContext, closedDate: {franchise: string, date: DateObject, message: string}): void {
      const params = {
        franchise: closedDate.franchise,
        date: closedDate.date.toSystemDate(),
      };
      return Vue.prototype.$backendRequestService.post(`admin/closed-date/remove`, params)
        .then(() => {
          context.commit('addSuccessMessage', 'Sluitingsdatum staat weer open', {root: true});
          context.dispatch('fetchData');
        }).catch((error: AxiosError) => context.commit('addError', error, {root: true}))
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    addClosedDateModal(state: DefaultState): boolean {
      return state.addClosedDateModal;
    },
    activeFranchise(state: DefaultState): string|null {
      return state.activeFranchise;
    },
    franchises(state: DefaultState): OpeningTimes[] {
      return state.franchiseOpeningTimes;
    },
    addingClosedDateError(state: DefaultState): string|null {
      return state.addingClosedDateError;
    },
  }
}
