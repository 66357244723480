import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import BackendRequestService from "./service/BackendRequestService";
import i18n from './i18n';
import initializeGoogleMaps from "./plugins/googleMaps";
import Loader from "@/components/Generic/Loader.vue";
import Panel from "@/components/Generic/Panel.vue";
import DarkPanel from "@/components/Generic/DarkPanel.vue";

Vue.prototype.$backendUrl = process.env.VUE_APP_BACKEND_URL;
Vue.prototype.$frontendUrl = process.env.VUE_APP_FRONTEND_URL;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(BackendRequestService);

Vue.component('loader', Loader);
Vue.component('panel', Panel);
Vue.component('dark-panel', DarkPanel);

Vue.config.productionTip = false
initializeGoogleMaps();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
