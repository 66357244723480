import { render, staticRenderFns } from "./DarkPanel.vue?vue&type=template&id=128e5541&scoped=true&"
import script from "./DarkPanel.vue?vue&type=script&lang=js&"
export * from "./DarkPanel.vue?vue&type=script&lang=js&"
import style0 from "./DarkPanel.vue?vue&type=style&index=0&id=128e5541&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128e5541",
  null
  
)

export default component.exports