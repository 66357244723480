import {CsNotification} from "@/store/ValueObject/CsNotification";

export class DefaultState {
  collapsedMenu = true;
  superUser = false;
  overWriteFranchise: null|string = null;
  userToken: string|null = null;
  currentFranchise = '';
  notifications: CsNotification[] = [];
  loginError: string|null = null;
  googleAuthUrl: string|null = null;
  googleAuthCode: string|null = null;
}
