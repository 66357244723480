export default class CurrentMonth {
  totalOrders: number;
  totalEuros: number;

  constructor(
    totalOrders: number,
    totalEuros: number,
  ) {
    this.totalOrders = totalOrders;
    this.totalEuros = totalEuros;
  }
}
