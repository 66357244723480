import Vue from "vue";
import DefaultState from "@/components/Settings/ValueObject/DefaultState";
import {StoreContext} from "@/components/Settings/ValueObject/StoreContext";
import {AxiosError} from "axios";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    setOldPassword(state: DefaultState, password: string): void {
      state.oldPassword = password;
    },
    setPassword(state: DefaultState, password: string): void {
      state.password = password;
    },
    setRePassword(state: DefaultState, rePassword: string): void {
      state.rePassword = rePassword;
    },
  },
  actions: {
    saveNewPassword(context: StoreContext): void {
      const params = {
        oldPassword: context.state.oldPassword,
        password: context.state.password,
        rePassword: context.state.rePassword
      };

      Vue.prototype.$backendRequestService.post('admin/change-password', params)
        .then(() => {
          context.commit('addSuccessMessage', 'Nieuw wachtwoord opgeslagen', {root: true});
          context.commit('setOldPassword', '');
          context.commit('setPassword', '');
          context.commit('setRePassword', '');
        })
        .catch((error: AxiosError) => {
          context.commit('addError', error, {root: true});
          throw error;
        });
    },
  },
  getters: {
    oldPassword(state: DefaultState): string {
      return state.oldPassword;
    },
    password(state: DefaultState): string {
      return state.password;
    },
    rePassword(state: DefaultState): string {
      return state.rePassword;
    },
  }
}
