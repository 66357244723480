import {ContentPage} from "@/components/Content/ValueObject/ContentPage";
import {CurrentPage} from "@/components/Content/ValueObject/CurrentPage";
import {Franchise} from "@/components/Content/ValueObject/Franchise";
import {Product} from "@/components/Content/ValueObject/Product";

export default class DefaultState {
  loading = false;
  contentPages: ContentPage[] = [];
  currentPage: CurrentPage = {
    id: null,
    title: '',
    url: '',
    metaDescription: '',
    metaKeywords: '',
    headerImage: '',
    pickedFranchise: null,
    pickedProducts: [],
    content: '',
  }

  allProducts: Product[] = [];

  franchises: Franchise[] = []
}
