import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMain,[_c(VContainer,{staticClass:"login",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align-content":"center"}},[_c(VCol,{attrs:{"align":"center"}},[_c(VForm,[_c(VCard,{attrs:{"width":"500"}},[_c(VCardTitle,[_c(VCol,{attrs:{"align-self":"center"}},[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"alt":"Company Sandwich logo","max-width":"125"}})],1)],1),_c(VCardText,[(_vm.googleAuthUrl)?_c(VBtn,{staticClass:"login-with-google",attrs:{"href":_vm.googleAuthUrl,"color":"secondary"}},[_c('img',{attrs:{"height":"20px","src":require("@/assets/google-logo.png"),"alt":""}}),_vm._v(" Login met Google ")]):_vm._e(),(_vm.loginError)?_c(VAlert,{attrs:{"align":"left","type":"error"}},[_vm._v(_vm._s(_vm.loginError))]):_vm._e(),_c(VTextField,{attrs:{"id":"username","label":"Gebruikersnaam"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"id":"password","type":"password","label":"Wachtwoord"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm.triggerLogin.apply(null, arguments)}}},[_vm._v("Inloggen")]),_c(VBtn,{attrs:{"type":"submit","small":""},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/reset-password')}}},[_vm._v("Reset password")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }