<template>
  <v-card elevation="0" class="switch-to-franchise">
    <v-img>
      <v-icon class="icon">mdi-account-arrow-right</v-icon>
    </v-img>
    <v-card-title class="card-title">
      Wissel van franchise
    </v-card-title>
    <v-card-text class="franchise-list__link">
      <v-btn
        elevation="2"
        x-small
        v-for="franchise in franchises"
        :key="franchise.id"
        @click.prevent="$store.dispatch('switchToFranchise', franchise.id)"
      >{{ franchise.name }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "SwitchToFranchise",
  computed: {
    ...mapGetters({
      franchises: 'dashboard/franchises',
    }),
  },
})
</script>

<style scoped>
.switch-to-franchise {
  padding: 1rem;
  text-align: center;
}

.card-title {
  text-align: center;
  justify-content: center;
}

.icon {
  font-size: 3rem;
}

.franchise-list__link {
  margin-bottom: 1.30rem;
}
</style>
