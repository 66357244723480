import DateObject from "@/service/DateObject";

export class NewCustomers {
  date: string;
  customers: number;

  constructor(date: string, customers: number) {
    this.date = date;
    this.customers = customers;
  }
}

export class Customer {
  id: string;
  customerEmail: string;
  createdDate: DateObject|string;

  constructor(id: string, customerEmail: string, createdDate: DateObject|string) {
    this.id = id;
    this.customerEmail = customerEmail;
    this.createdDate = createdDate;
  }
}

export class DefaultState {
  months: {text: string, value: number}[] = [
    {text: 'januari', value: 1},
    {text: 'februari', value: 2},
    {text: 'maart', value: 3},
    {text: 'april', value: 4},
    {text: 'mei', value: 5},
    {text: 'juni', value: 6},
    {text: 'juli', value: 7},
    {text: 'augustus', value: 8},
    {text: 'september', value: 9},
    {text: 'oktober', value: 10},
    {text: 'november', value: 11},
    {text: 'december', value: 12},
  ];
  currentYear: number;
  currentMonth: number;
  newCustomers: NewCustomers[] = [];
  customers: Customer[] = [];

  constructor() {
    this.currentYear = DateObject.now().year();
    this.currentMonth = DateObject.now().month();
  }
}
