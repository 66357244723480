import Vue from "vue";
import DefaultState from "../ValueObject/DefaultState";
import {Address} from "../ValueObject/Address";
import {Customer} from "../ValueObject/Customer";
import {OfferData} from "../ValueObject/OfferData";
import {Product} from "../ValueObject/Product";
import {PickedAddresses} from "../ValueObject/PickedAddresses";
import {OverviewOffer} from "../ValueObject/OverviewOffer";
import {OverviewResponse} from "../ValueObject/OverviewResponse";
import {OfferEditResponse} from "../ValueObject/OfferEditResponse";
import DateObject from "@/service/DateObject";
import {PaymentMethod} from "@/components/Offer/ValueObject/PaymentMethod";
import {StoreContext} from "@/components/Offer/ValueObject/StoreContext";

function move(input: Product[], from: number, to: number): Product[] {
  let numberOfDeletedElm = 1;

  const elm = input.splice(from, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  input.splice(to, numberOfDeletedElm, elm);

  return input;
}

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    clearCustomer(state: DefaultState): void {
      state.customer = null;
    },
    updateFranchise(state: DefaultState, franchise: {id: string}): void {
      state.franchise = franchise;
    },
    updateOfferId(state: DefaultState, offerId: string): void {
      state.offerId = offerId;
    },
    updateOfferData(state: DefaultState, offerData: OfferData): void {
      state.offerData = offerData;
    },
    updateCustomerInfo(state: DefaultState, customer: Customer): void {
      state.customer = customer;
    },
    updateAddresses(state: DefaultState, addresses: PickedAddresses): void {
      state.addresses = addresses;
    },
    updatePaymentMethods(state: DefaultState, paymentMethods: PaymentMethod[]): void {
      state.paymentMethods = paymentMethods;
    },
    updateProducts(state: DefaultState, products: Product[]): void {
      state.products = products;
    },
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    updateDeliveryAddress(state: DefaultState, addressId: string): void {
      state.addresses.deliveryAddress = addressId;
    },
    updateInvoiceAddress(state: DefaultState, addressId: string): void {
      state.addresses.invoiceAddress = addressId;
    },
    removeInvoiceAddress(state: DefaultState): void {
      state.addresses.invoiceAddress = null;
    },
    addProduct(state: DefaultState): void {
      state.products.push({
        uniqueId: Math.random().toString(36).substring(7),
        title: '',
        amount: 1,
        price: 0,
        tax: 9,
        total: 0,
      });
    },
    removeProduct(state: DefaultState, customProductIndex: number): void {
      state.products.splice(customProductIndex, 1);
    },
    moveProductUp(state: DefaultState, index: number): void {
      const newIndex = index - 1;
      if (newIndex < 0) {
        return;
      }

      state.products = move(state.products, index, newIndex);
    },
    moveProductDown(state: DefaultState, index: number): void {
      const newIndex = index + 1;
      if (newIndex > state.products.length) {
        return;
      }

      state.products = move(state.products, index, newIndex);
    },
    setSendOffer(state: DefaultState, sendOffer: boolean): void {
      state.sendOffer = sendOffer;
    },
    setDeliveryTime(state: DefaultState, time: {hours: number, minutes: number}): void {
      state.offerData.deliveryDate = state.offerData.deliveryDate.setTime(time.hours, time.minutes);
    },
    setDeliveryDate(state: DefaultState, date: {year: number, month: number, day: number}): void {
      state.offerData.deliveryDate = state.offerData.deliveryDate.setDate(date.year, date.month, date.day);
    },
    setOverviewOffers(state: DefaultState, overviewOffers: OverviewOffer[]): void {
      state.overviewOffers = overviewOffers;
    }
  },
  actions: {
    fetchFranchiseInfo(context: StoreContext): void {
      Vue.prototype.$backendRequestService.post('get-franchise-by-postcode', {postcode: context.getters.deliveryAddress.postcode}).then((data: {franchise: {id: string}}) => {
        context.commit('updateFranchise', data.franchise);
      }).catch(() => {
        context.commit('addWarning', 'Geen vestiging bij dit bezorgadres gevonden', {root: true});
      });
    },
    fetchCustomer(context: StoreContext, customerId: string): void {
      Vue.prototype.$backendRequestService.get(`customer/${customerId}`).then((data: {customer: Customer, paymentMethods: PaymentMethod[]}) => {
        context.commit('updateCustomerInfo', data.customer);
        context.commit('updatePaymentMethods', data.paymentMethods);
        data.customer.addresses.map((address: Address) => {
          if (address.default) {
            context.commit('updateDeliveryAddress', address.id);
            context.dispatch('fetchFranchiseInfo');
          }

          if (address.billingAddress) {
            context.commit('updateInvoiceAddress', address.id);
          }
        });
      }).catch(Vue.prototype.showError).finally(() => {
        context.commit('doneLoading');
      });
    },
    calculateTotals(context: StoreContext): void {
      context.state.products.map(product => {
        product.total = product.amount * parseFloat(product.price.toString().replace(',', '.'));
      });
    },
    saveOffer(context: StoreContext): void {
      const data = {
        customerId: context.state.customer != null ? context.state.customer.id : null,
        paymentMethod: context.state.offerData.paymentMethod,
        deliveryMoment: context.state.offerData.deliveryDate.toSystemDateTime(),
        costCenter: context.state.offerData.costCenter,
        deliveryCosts: context.state.offerData.deliveryCosts,
        title: context.state.offerData.title,
        description: context.state.offerData.description,
        remark: context.state.offerData.remark,
        deliveryAddress: context.state.addresses.deliveryAddress,
        invoiceAddress: context.state.addresses.invoiceAddress,
        products: context.state.products,
      };

      if (context.state.offerId) {
        return Vue.prototype.$backendRequestService.post(`update-offer/${context.state.offerId}`, data);
      }

      return Vue.prototype.$backendRequestService.post('create-offer', data);
    },
    fetchOffer(context: StoreContext, offerId: string): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get(`offer/${offerId}`).then((data: OfferEditResponse) => {
        context.commit('updateOfferId', offerId);
        context.commit('updateCustomerInfo', data.customer);
        context.commit('updatePaymentMethods', data.paymentMethods);
        context.commit('updateOfferData', {
          deliveryDate: DateObject.fromDateString(data.offer.genericData.deliveryDate),
          costCenter: data.offer.genericData.costCenter,
          remark: data.offer.genericData.remark,
          paymentMethod: data.offer.genericData.paymentMethod,
          deliveryCosts: data.offer.genericData.deliveryCosts,
          title: data.offer.genericData.title,
          description: data.offer.genericData.description,
        });
        context.commit('updateAddresses', data.offer.addresses);
        context.commit('updateProducts', data.offer.products);
      }).finally(() => {
        context.commit('doneLoading');
      });
    },
    fetchOffersForOverview(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('offers').then((data: OverviewResponse) => {
        context.commit('setOverviewOffers', data.offers.map(offer => {
          return {
            id: offer.id,
            number: offer.number,
            customerName: offer.customerName,
            company: offer.company,
            created: DateObject.fromDateString(offer.created),
            orderId: offer.orderId,
          };
        }));

        context.commit('doneLoading');
      });

    },
    convertToOrder(context: StoreContext, offerId: string): void {
      Vue.prototype.$backendRequestService.post(`offer/${offerId}/convert-to-order`, {}).then(() => {
        context.commit('addSuccessMessage', 'Bestelling geplaatst', {root: true})
        context.dispatch('fetchOffersForOverview');
      }).catch(Vue.prototype.showError);
    }
  },
  getters: {
    offerId(state: DefaultState): string|null {
      return state.offerId;
    },
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    franchise(state: DefaultState): {id: string}|null {
      return state.franchise;
    },
    offerData(state: DefaultState): OfferData {
      return state.offerData;
    },
    customer(state: DefaultState): Customer|null {
      return state.customer;
    },
    addresses(state: DefaultState): {deliveryAddress: string | null, invoiceAddress: string | null} {
      return state.addresses;
    },
    paymentMethods(state: DefaultState): PaymentMethod[] {
      return state.paymentMethods;
    },
    products(state: DefaultState): Product[] {
      return state.products;
    },
    deliveryAddress(state: DefaultState): Address|null {
      if (!state.customer) {
        return null;
      }

      for (let i = 0; i < state.customer.addresses.length; i++) {
        if (state.customer.addresses[i].id === state.addresses.deliveryAddress) {
          return state.customer.addresses[i];
        }
      }

      return null;
    },
    invoiceAddress(state: DefaultState): Address|null {
      if (!state.customer) {
        return null;
      }

      for (let i = 0; i < state.customer.addresses.length; i++) {
        if (state.customer.addresses[i].id === state.addresses.invoiceAddress) {
          return state.customer.addresses[i];
        }
      }

      return null;
    },
    customerDeliveryAddresses(state: DefaultState): Address[] {
      if (!state.customer) {
        return [];
      }

      return state.customer.addresses;
    },
    customerInvoiceAddresses(state: DefaultState): Address[] {
      if (!state.customer) {
        return [];
      }

      return state.customer.addresses.filter(address => address.billingAddress);
    },
    overviewOffers(state: DefaultState): OverviewOffer[] {
      return state.overviewOffers;
    }
  }
}
