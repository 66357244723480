import Vue from "vue";
import {AxiosError} from "axios";
import {StoreContext} from "@/components/Generic/StoreContext";

interface ChildProduct {
}

class DefaultState {
  dialog: boolean = false;
  childProducts: ChildProduct[] = [];
}

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    setChildProducts(state: DefaultState, childProducts: ChildProduct[]): void{
      state.childProducts = childProducts;
    },
    setDialog(state: DefaultState, dialog: boolean): void {
      state.dialog = dialog;
    }
  },
  actions: {
    saveSubProduct(context: StoreContext, product: Record<string, unknown>[]): void {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!product.id) {
        return;
      }

      Vue.prototype.$backendRequestService.post('sub-product/save', product).then(() => {
        context.commit('addSuccessMessage', 'Subproduct is opgeslagen', {root: true})
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    saveNewSubProduct(context: StoreContext, product: Record<string, unknown>[]): void {
      return Vue.prototype.$backendRequestService.post('sub-product/add', product).then(() => {
        context.commit('addSuccessMessage', 'Subproduct is opgeslagen', {root: true});
        context.commit('setDialog', false);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    }
  },
  getters: {
    childProducts(state: DefaultState): ChildProduct[] {
      return state.childProducts;
    },
    dialog(state: DefaultState): boolean {
      return state.dialog;
    },
  }
}
