<template>
  <v-card :href="$router.resolve({path: `/order?date=${date.toSystemDate()}`}).href" elevation="0" class="orders-tomorrow">
    <v-img>
      <v-icon class="icon">mdi-truck-delivery</v-icon>
    </v-img>
    <v-card-title class="card-title">
      {{ ordersTomorrow }} bestellingen
    </v-card-title>
    <v-card-subtitle>&nbsp;</v-card-subtitle>
    <v-card-text>{{ date.toOnlyDateString() }}</v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "OrdersTomorrow",
  computed: {
    ...mapGetters('dashboard', {
      ordersTomorrow: 'ordersTomorrow',
      date: 'date',
    }),
  }
})
</script>

<style scoped>
.orders-tomorrow {
  padding: 1rem;
  text-align: center;
}

.card-title {
  text-align: center;
  justify-content: center;
}

.icon {
  font-size: 3rem;
}
</style>
