import DateObject from "@/service/DateObject";
import {AvailableFranchise} from "@/components/Order/ValueObjects/AvailableFranchise";
import {Order} from "@/components/Order/ValueObjects/Order";

export default class OverviewDefaultState {
  loading = false;
  orders: Order[] = [];
  date: DateObject|null = null;
  page = 1;
  amountPerPage = 50;
  totalOrders = 0;
  includeCancelled = true;
  includeFutureOrders = true;
  searchQuery = '';
  searchTimeout: ReturnType<typeof setTimeout>|null = null;
  paymentMethods: string[] = [];
  selectedPaymentMethods: string[] = [];
  selectedFranchises: string[] = [];
  availableFranchises: AvailableFranchise[] = [];
  duplicatingOrder = false;
  fetchingOrderToDuplicate = false;
  orderIdToDuplicate: string|null = null;
  orderToDuplicate: Order|null = null;
}
