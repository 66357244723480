import DateObject from "@/service/DateObject";
import {OverviewStoreContext} from "@/components/Order/ValueObjects/OverviewStoreContext";
import Vue from "vue";
import {AxiosError} from "axios";
import DefaultState from "@/components/OrdersToInvoice/ValueObject/DefaultState";
import {Order} from "@/components/OrdersToInvoice/ValueObject/Order";
import {StoreContext} from "@/store/ValueObject/StoreContext";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setOrders(state: DefaultState, orders: Order[]): void {
      state.orders = orders;
    },
    addToInvoicesConverting(state: DefaultState, invoiceId: string): void {
      const index = state.invoicesConverting.indexOf(invoiceId);
      if (index !== -1) {
        return;
      }

      state.invoicesConverting.push(invoiceId);
    },
    removeFromInvoicesConverting(state: DefaultState, invoiceId: string): void {
      const index = state.invoicesConverting.indexOf(invoiceId);
      if (index === -1) {
        return;
      }

      state.invoicesConverting = state.invoicesConverting.filter(item => item !== invoiceId);
    },
  },
  actions: {
    fetchData(context: OverviewStoreContext): void {
      context.commit('startLoading');

      Vue.prototype.$backendRequestService.get('orders-to-invoice')
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}))
        .then((data: {orders: Order[]}) => {
          context.commit('setOrders', data.orders.map(order => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order.orderDate = DateObject.fromDateString(order.orderDate);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order.deliveryDate = DateObject.fromDateString(order.deliveryDate);

            return order;
          }));
        })
        .finally(() => context.commit('doneLoading'));
    },
    createInvoice(
      context: StoreContext,
      {orderId, sendInvoice, setToPaid}: {orderId: string, sendInvoice: boolean, setToPaid: boolean}
    ): void {
      context.commit('addToInvoicesConverting', orderId);

      Vue.prototype.$backendRequestService.post('admin-orders/convert', {'orders': [orderId], setToPaid, sendInvoice}).then(() => {
        context.dispatch('fetchData');
        context.commit('addNotification', 'Factuur aangemaakt', {root: true});
      }).catch((error: Error) => {
        context.commit('addError', error, {root: true});
      }).finally(() => {
        context.commit('removeFromInvoicesConverting', orderId);
      })
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    orders(state: DefaultState): Order[] {
      return state.orders;
    },
    invoicesConverting(state: DefaultState): string[] {
      return state.invoicesConverting;
    }
  }
}
