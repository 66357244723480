<template>
  <div class="loader">
    <v-progress-circular indeterminate color="primary"/>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Loader"
});
</script>

<style scoped>
.loader {
  width: 100%;
  padding: 1rem;
}
</style>
