<template>
  <v-col align="center" v-if="resetPasswordRequested && !token">
    <v-form>
      <v-card width="400">
        <v-card-title>
          <v-col align-self="center">
            <v-img src="@/assets/logo.png" alt="Company Sandwich logo" max-width="125"/>
          </v-col>
        </v-card-title>
        <v-card-text class="password-requested">
          In het geval dat uw e-mail bekend is ontvangt u binnen een paar minuten een e-mail waarin de stappen staan om uw wachtwoord te resetten.
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" @click.prevent="$router.push('/')" small>Terug naar inloggen</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-col>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "PasswordRequestedMessage",
  computed: {
    ...mapGetters('passwordReset', ['resetPasswordRequested', 'token'])
  },
})
</script>
