import {StoreContext} from "@/store/ValueObject/StoreContext";
import Vue from "vue";
import {AxiosError} from "axios/index";

class DefaultState{
  succesfulMatches: number = 0;
  failedMatches: number = 0;
}

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    setSuccesfulMatches(state: DefaultState, succesfulMatches: number): void {
      state.succesfulMatches = succesfulMatches;
    },
    setFailedMatches(state: DefaultState, failedMatches: number): void {
      state.failedMatches = failedMatches;
    },
  },
  actions: {
    fetchData(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get('transactions-dashboard').then((data: {succesfulMatches: number, failedMatches: number}) => {
        context.commit('setSuccesfulMatches', data.succesfulMatches);
        context.commit('setFailedMatches', data.failedMatches);
      });
    },
  },
  getters: {
    succesfulMatches(state: DefaultState): number {
      return state.succesfulMatches;
    },
    failedMatches(state: DefaultState): number {
      return state.failedMatches;
    },
  }
}
