import Vue from 'vue';
import Vuex from 'vuex';
import PersistentStorePlugin from "./PersistentStorePlugin";
import DashboardStoreModule from "@/components/Dashboard/Store/DashboardStoreModule";
import PostCodeStoreModule from "@/components/PostcodeManagement/Store/PostCodeStoreModule";
import FranchiseInvoiceStoreModule from "@/components/FranchiseInvoice/Store/FranchiseInvoiceStoreModule";
import {AxiosError} from "axios";
import {DefaultState} from "@/store/ValueObject/DefaultState";
import {StoreContext} from "@/store/ValueObject/StoreContext";
import ContentStoreModule from "@/components/Content/Store/ContentStoreModule"
import CustomerStoreModule from "@/components/Customer/Store/CustomerStoreModule";
import OfferStoreModule from "@/components/Offer/Store/OfferStoreModule";
import OrderOverviewStoreModule from "@/components/Order/store/OrderOverviewStoreModule";
import OrderStoreModule from "@/components/Order/store/OrderStoreModule";
import InvoicesStoreModule from "@/components/Invoice/Store/InvoicesStoreModule";
import CategoryStoreModule from "@/components/Category/Store/CategoryStoreModule";
import RouteplannerStoreModule from "@/components/RoutePlanner/Store/RouteplannerStoreModule";
import OpeningTimesStoreModule from "@/components/OpeningTimes/Store/OpeningTimesStoreModule";
import ResetPasswordStoreModule from "@/components/ResetPassword/Store/ResetPasswordStoreModule";
import SettingsStoreModule from "@/components/Settings/Store/SettingsStoreModule";
import ProductStoreModule from "@/components/Product/Store/ProductStoreModule";
import ToInvoiceStoreModule from "@/components/Dashboard/Store/ToInvoiceStoreModule";
import OrdersToInvoiceStoreModule from "@/components/OrdersToInvoice/Store/OrdersToInvoiceStoreModule";
import OrdersToMergeInvoiceStoreModule from "@/components/OrdersToMergeInvoice/Store/OrdersToMergeInvoiceStoreModule";
import router from '../router';
import PostcodesStoreModule from "@/components/Postcodes/Store/PostcodesStoreModule";
import ReportsStoreStoreModule from "@/components/Reports/Store/ReportsStoreStoreModule";
import DebitorsDashboardStoreModule from "@/components/Dashboard/Store/DebitorsDashboardStoreModule";
import DebitorStoreModule from "@/components/Debitors/Store/DebitorStoreModule";
import ConnectBankStoreModule from "@/components/ConnectBank/Store/ConnectBankStoreModule";
import TransactionStoreModule from "@/components/Transaction/Store/TransactionStoreModule";
import TransactionDashboardStoreModule from "@/components/Dashboard/Store/TransactionDashboardStoreModule";
import KitchenListStoreModule from "@/components/KitchenList/Store/KitchenListStoreModule";
import CustomerStatisticsStoreModule from "@/components/CustomerStatistics/Store/CustomerStatisticsStoreModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: (): DefaultState => new DefaultState(),
  mutations: {
    addError(state: DefaultState, error: AxiosError) {
      let message = 'Onbekende fout opgetreden';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.response && error.response.data && error.response.data.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message = error.response.data.error;
      }

      state.notifications.push({
        type: 'error',
        message,
      });
      setTimeout(() => {
        state.notifications.shift();
      }, 30000);
    },
    addWarning(state: DefaultState, message: string) {
      state.notifications.push({
        type: 'error',
        message,
      });
      setTimeout(() => {
        state.notifications.shift();
      }, 30000);
    },
    addNotification(state: DefaultState, message: string) {
      state.notifications.push({
        type: 'info',
        message,
      });
      setTimeout(() => {
        state.notifications.shift();
      }, 5000);
    },
    addSuccessMessage(state: DefaultState, message: string) {
      state.notifications.push({
        type: 'success',
        message,
      });
      setTimeout(() => {
        state.notifications.shift();
      }, 5000);
    },
    setUserToken(state: DefaultState, token) {
      state.userToken = token;
    },
    clearUserToken(state: DefaultState) {
      state.userToken = null;
    },
    clearOverwrittenFranchise(state: DefaultState) {
      state.overWriteFranchise = null;
    },
    setCurrentFranchise(state: DefaultState, franchise: string) {
      state.currentFranchise = franchise;
    },
    activateSuperUser(state: DefaultState) {
      state.superUser = true;
    },
    deactivateSuperUser(state: DefaultState) {
      state.superUser = false;
    },
    collapseMenu(state: DefaultState) {
      state.collapsedMenu = true;
    },
    showMenu(state: DefaultState) {
      state.collapsedMenu = false;
    },
    loginAsFranchise(state: DefaultState, franchiseId: string) {
      state.overWriteFranchise = franchiseId;
    },
    setLoginError(state: DefaultState, error: string|null): void {
      state.loginError = error;
    },
    setGoogleAuthUrl(state: DefaultState, link: string): void {
      state.googleAuthUrl = link;
    },
    setGoogleAuthCode(state: DefaultState, code: string): void {
      state.googleAuthCode = code;
    }
  },
  actions: {
    toggleMenu(context: StoreContext) {
      if (context.state.collapsedMenu) {
        return context.commit('showMenu');
      }

      return context.commit('collapseMenu');
    },
    retrieveGoogleAuthUrl(context: StoreContext) {
      Vue.prototype.$backendRequestService.get('admin/social-login-url')
        .then((data: {link: string}) => {
          context.commit('setGoogleAuthUrl', data.link);
        });
    },
    loginWithGoogleToken(context: StoreContext) {
      Vue.prototype.$backendRequestService.post('admin/social-login', {authcode: context.getters.googleAuthCode})
        .catch((error: AxiosError) => {
          context.commit('addError', error);
          router.push('login');
        })
        .then((data: {token: string}) => {
          if (data.token) {
            Vue.prototype.$backendRequestService.updateToken(data.token);
            context.commit('setUserToken', data.token);

            context.dispatch('fetchUser');
            router.push('/');
          }
        });
    },
    login(context: StoreContext, credentials: {username: string, password: string}) {
      context.commit('setLoginError', null);
      if (credentials.username === '') {
        return context.commit('setLoginError', 'Geen geldige gebruikersnaam opgegeven');
      }

      if (credentials.password === '') {
        return context.commit('setLoginError', 'Geen geldig wachtwoord opgegeven');
      }

      Vue.prototype.$backendRequestService.post('admin-login', credentials)
        .catch((error: AxiosError) => {
          let message = 'Onbekende fout opgetreden';
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.response && error.response.data && error.response.data.error) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            message = error.response.data.error;
          }

          context.commit('setLoginError', message)
        })
        .then((data: {token: string}) => {
          if (data.token) {
            Vue.prototype.$backendRequestService.updateToken(data.token);
            context.commit('setUserToken', data.token);

            context.dispatch('fetchUser');
          }
        });
    },
    logout(context: StoreContext) {
      context.commit('clearUserToken');
      Vue.prototype.$backendRequestService.updateToken('');
      Vue.prototype.$backendRequestService.updateFranchiseId(null);
    },
    setUserToken(context: StoreContext) {
      if (context.state.userToken) {
        Vue.prototype.$backendRequestService.updateToken(context.state.userToken);
      }

      if (context.state.overWriteFranchise) {
        Vue.prototype.$backendRequestService.updateFranchiseId(context.state.overWriteFranchise);
        context.dispatch('reloadPage');
      }
    },
    switchToSuperUser(context: StoreContext) {
      context.commit('clearOverwrittenFranchise');
      Vue.prototype.$backendRequestService.updateFranchiseId(null);
      context.dispatch('reloadPage');
    },
    switchToFranchise(context: StoreContext, franchiseId: string) {
      Vue.prototype.$backendRequestService.updateFranchiseId(franchiseId);
      context.commit('loginAsFranchise', franchiseId);
      context.dispatch('reloadPage');
    },
    reloadPage(context: StoreContext) {
      context.dispatch('fetchUser');
      if (context.getters.loggedIn) {
        context.dispatch('dashboard/fetchDashboardData', null, {root: true});
        context.dispatch('dashboard/fetchStatistics', null, {root: true});
        context.dispatch('toInvoice/fetchData', null, {root: true});
        context.dispatch('debitorsDashboard/fetchData', null, {root: true});
        context.dispatch('transactionDashboard/fetchData', null, {root: true});
      }
    },
    fetchUser(context: StoreContext) {
      if (!context.getters.loggedIn) {
        return;
      }

      Vue.prototype.$backendRequestService.get('user-data')
        .then((data: {franchise: string, superUser: boolean}) => {
          if (data.franchise) {
            context.commit('setCurrentFranchise', data.franchise);
          }

          if (data.superUser) {
            return context.commit('activateSuperUser');
          }

          return context.commit('deactivateSuperUser');
        })
        .catch((error: AxiosError) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.message === "Network Error" || error.response.status === 403) {
            context.commit('addWarning', 'U bent automatisch uitgelogd omdat uw sessie niet meer geldig was.');
            context.dispatch('logout');
            return;
          }

          context.commit('addError', error);
        });
    }
  },
  getters: {
    loggedIn(state: DefaultState) {
      return !!state.userToken;
    },
    notifications(state: DefaultState) {
      return state.notifications;
    },
    currentFranchise(state: DefaultState) {
      return state.currentFranchise;
    },
    superUser(state: DefaultState) {
      return state.superUser;
    },
    overWrittenFranchise(state: DefaultState) {
      return !!state.overWriteFranchise;
    },
    collapsedMenu(state: DefaultState) {
      return state.collapsedMenu;
    },
    loginError(state: DefaultState) {
      return state.loginError;
    },
    googleAuthUrl(state: DefaultState) {
      return state.googleAuthUrl;
    },
    googleAuthCode(state: DefaultState) {
      return state.googleAuthCode;
    },
  },
  modules: {
    category: {namespaced: true, ...CategoryStoreModule},
    customer: {namespaced: true, ...CustomerStoreModule},
    customerStatistics: {namespaced: true, ...CustomerStatisticsStoreModule},
    dashboard: {namespaced: true, ...DashboardStoreModule},
    debitorsDashboard: {namespaced: true, ...DebitorsDashboardStoreModule},
    debitor: {namespaced: true, ...DebitorStoreModule},
    franchiseInvoice: {namespaced: true, ...FranchiseInvoiceStoreModule},
    invoice: {namespaced: true, ...InvoicesStoreModule},
    nextGenContent: {namespaced: true, ...ContentStoreModule},
    offer: {namespaced: true, ...OfferStoreModule},
    openingTimes: {namespaced: true, ...OpeningTimesStoreModule},
    order: {namespaced: true, ...OrderStoreModule},
    orderOverview: {namespaced: true, ...OrderOverviewStoreModule},
    ordersToInvoice: {namespaced: true, ...OrdersToInvoiceStoreModule},
    ordersToMergeInvoice: {namespaced: true, ...OrdersToMergeInvoiceStoreModule},
    passwordReset: {namespaced: true, ...ResetPasswordStoreModule},
    postCode: {namespaced: true, ...PostCodeStoreModule},
    postcodes: {namespaced: true, ...PostcodesStoreModule},
    product: {namespaced: true, ...ProductStoreModule},
    reports: {namespaced: true, ...ReportsStoreStoreModule},
    routePlanner: {namespaced: true, ...RouteplannerStoreModule},
    settings: {namespaced: true, ...SettingsStoreModule},
    toInvoice: {namespaced: true, ...ToInvoiceStoreModule},
    connectBank: {namespaced: true, ...ConnectBankStoreModule},
    transaction: {namespaced: true, ...TransactionStoreModule},
    transactionDashboard: {namespaced: true, ...TransactionDashboardStoreModule},
    kitchenList: {namespaced: true, ...KitchenListStoreModule},
  },
  plugins: [PersistentStorePlugin]
})
