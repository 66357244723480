import {StoreContext} from "../../Generic/StoreContext";
import Vue from "vue";
import {AxiosError} from "axios";
import DateObject from "@/service/DateObject";

class DefaultState {
  loading: boolean = false;
  invoices: Invoice[] = [];
  totalInvoices: number = 0;
}

interface Invoice {
  invoiceNumber: string;
  customer: string;
  lastAction: DateObject|string;
  typeOfAction: string;
  total: string;
}

interface InvoicesResponse {
  invoices: Invoice[];
  totalInvoices: number;
}

export default {
  state: (): DefaultState => <DefaultState>(new DefaultState()),
  mutations: {
    setLoading(state: DefaultState, loading: boolean): void {
      state.loading = loading;
    },
    setInvoices(state: DefaultState, invoices: Invoice[]): void {
      state.invoices = invoices;
    },
    setTotalInvoices(state: DefaultState, total: number): void {
      state.totalInvoices = total;
    },
  },
  actions: {
    fetchList(context: StoreContext): void {
      context.commit('setLoading', true);
      Vue.prototype.$backendRequestService.get('invoice/debitor-list').then((data: InvoicesResponse) => {
        data.invoices = data.invoices.map((invoice: Invoice) => {
          if (typeof invoice.lastAction === 'string') {
            invoice.lastAction = DateObject.fromDateString(invoice.lastAction);
          }

          return invoice;
        })
        context.commit('setInvoices', data.invoices);
        context.commit('setTotalInvoices', data.totalInvoices);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => {
        context.commit('setLoading', false);
      });
    },
    payInvoice(context: StoreContext, id: string): void {
      Vue.prototype.$backendRequestService.post('payment-received/' + id)
        .then(() => {
          context.commit('addSuccessMessage', 'Factuur betaald', {root: true});
          context.dispatch('fetchList');
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    sendReminder(context: StoreContext, data: {id: string, comment: string}): void {
      Vue.prototype.$backendRequestService.post(`admin-invoices/${data.id}/send-reminder`, {comment: data.comment})
        .then(() => {
          context.commit('addSuccessMessage', 'Herinnering verzonden', {root: true});
          context.dispatch('fetchList');
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
    postpone(context: StoreContext, id: string): void {
      Vue.prototype.$backendRequestService.post(`invoice-reminder/${id}/postpone`)
        .then(() => {
          context.commit('addNotification', 'Over twee weken verschijnt de factuur weer in deze lijst', {root: true});
          context.dispatch('fetchList');
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    },
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    invoices(state: DefaultState): Invoice[] {
      return state.invoices;
    },
    invoicesCount(state: DefaultState): number {
      return state.invoices.length;
    },
    totalInvoices(state: DefaultState): number {
      return state.totalInvoices;
    },
  }
}
