import Vue from "vue";
import {StoreContext} from "@/components/Generic/StoreContext";
import {AxiosError} from "axios";
import DateObject from "@/service/DateObject";

class DefaultState {
  loading: boolean = false;
  currentDate: DateObject = DateObject.now();
  categories: Category[] = [];
}

interface Product {
  name: number,
  quantity: number,
  variations: {
    options: string,
    remark: string,
    quantity: number,
  }[],
}

interface Category {
  name: string,
  products: Product[],
}

interface KitchenListInterface {
  productsTotals: {
    categories: Category[]
  }[];
}

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    setLoading(state: DefaultState, loading: boolean): void {
      state.loading = loading;
    },
    setDate(state: DefaultState, date: DateObject): void {
      state.currentDate = date;
    },
    setProductTotals(state: DefaultState, categories: Category[]): void {
      state.categories = categories;
    },
  },
  actions: {
    fetchKitchenList(context: StoreContext): void {
      context.commit('setLoading', true);
      Vue.prototype.$backendRequestService.get(`kitchen-list/${context.getters.currentDate.toSystemDate()}`)
        .then((data: KitchenListInterface) => {
          context.commit('setProductTotals', data.productsTotals);
        })
        .catch((error: AxiosError) => {
          context.commit('addError', error, {root: true});
          throw error;
        })
        .finally(() => {
          context.commit('setLoading', false);
        });
    },
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    currentDate(state: DefaultState): DateObject {
      return state.currentDate;
    },
    categories(state: DefaultState): Category[] {
      return state.categories;
    },
  }
}
