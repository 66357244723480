<template>
  <v-col align="center" v-if="!resetPasswordRequested && !token">
    <v-form>
      <v-card width="400">
        <v-card-title>
          <v-col align-self="center">
            <v-img src="@/assets/logo.png" alt="Company Sandwich logo" max-width="125"/>
          </v-col>
        </v-card-title>
        <v-card-text>
          Vul hieronder uw e-mailadres in en indien u een account heeft dan zal u binnen een aantal minuten een e-mail
          ontvangen waarin staat hoe u uw wachtwoord opnieuw kunt instellen.
        </v-card-text>
        <v-card-text>
          <v-text-field id="email" label="E-mailadres" v-model="_email" hide-details />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" id="send-email" type="submit" @click.prevent="sendResetPasswordMail" small>Versturen</v-btn>
          <v-btn type="submit" @click.prevent="$router.push('/')" small>Terug naar inloggen</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-col>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "RequestResetPasswordMailForm",
  computed: {
    ...mapGetters('passwordReset', ['email', 'resetPasswordRequested', 'resetPasswordError', 'token']),
    _email: {
      get() {
        return this.email;
      },
      set(value) {
        this.$store.commit('passwordReset/setEmail', value);
      },
    }
  },
  methods: {
    ...mapActions('passwordReset', ['sendResetPasswordMail'])
  }
})
</script>
