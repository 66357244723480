import DefaultState from "@/components/Content/ValueObject/DefaultState";
import {StoreContext} from "@/components/Content/ValueObject/StoreContext";
import {AxiosError} from "axios";
import Vue from "vue";
import {ContentPage} from "@/components/Content/ValueObject/ContentPage";
import {Franchise} from "@/components/Content/ValueObject/Franchise";
import {Product} from "@/components/Content/ValueObject/Product";
import {CurrentPage} from "@/components/Content/ValueObject/CurrentPage";
import router from "@/router";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    startLoading(state: DefaultState): void {
      state.loading = true;
    },
    doneLoading(state: DefaultState): void {
      state.loading = false;
    },
    setPages(state: DefaultState, pages: ContentPage[]): void {
      state.contentPages = pages;
    },
    setFranchises(state: DefaultState, franchises: Franchise[]): void {
      franchises.unshift({id: '', name: 'Geen'})
      state.franchises = franchises;
    },
    setCurrentPage(state: DefaultState, page: CurrentPage): void {
      state.currentPage = page;
    },
    setId(state: DefaultState, id: string): void {
      state.currentPage.id = id;
    },
    setTitle(state: DefaultState, title: string): void {
      state.currentPage.title = title;
    },
    setUrl(state: DefaultState, url: string): void {
      state.currentPage.url = url;
    },
    setMetaDescription(state: DefaultState, metaDescription: string): void {
      state.currentPage.metaDescription = metaDescription;
    },
    setMetaKeywords(state: DefaultState, metaKeywords: string): void {
      state.currentPage.metaKeywords = metaKeywords;
    },
    setHeaderImage(state: DefaultState, headerImage: string): void {
      state.currentPage.headerImage = headerImage;
    },
    setPickedFranchise(state: DefaultState, pickedFranchise: string): void {
      state.currentPage.pickedFranchise = pickedFranchise;
    },
    setPickedProducts(state: DefaultState, pickedProducts: string[]): void {
      state.currentPage.pickedProducts = pickedProducts;
    },
    setContent(state: DefaultState, content: string): void {
      state.currentPage.content = content;
    },
    setAllProducts(state: DefaultState, products: {
      id: string,
      category: string,
      price: number,
      name: string,
    }[]): void {
      state.allProducts = products;
    },
  },
  actions: {
    fetchMetaData(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get('next-gen-content-pages/meta-data').then((data: {franchises: Franchise[]}) => {
        context.commit('setFranchises', data.franchises);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally();
    },
    fetchContentPages(context: StoreContext): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('next-gen-content-pages').then((data: {pages: ContentPage[]}) => {
        context.commit('setPages', data.pages);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    deletePage(context: StoreContext, pageId: string): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.post('next-gen-content-pages/delete', {pageId}).then(() => {
        context.dispatch('fetchContentPages')
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    activatePage(context: StoreContext, pageId: string): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.post('next-gen-content-pages/activate', {pageId}).then(() => {
        context.dispatch('fetchContentPages')
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    deactivatePage(context: StoreContext, pageId: string): void {
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.post('next-gen-content-pages/deactivate', {pageId}).then(() => {
        context.dispatch('fetchContentPages')
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    fetchPage(context: StoreContext, pageId: string): void {
      if (pageId === 'new') {
        context.commit('setCurrentPage', {
          id: '',
          title: '',
          url: '',
          metaDescription: '',
          metaKeywords: '',
          headerImage: '',
          pickedFranchise: null,
          pickedProducts: [],
          content: ''
        });
        return;
      }
      context.commit('startLoading');
      Vue.prototype.$backendRequestService.get('next-gen-content-pages/' + pageId).then((data: {page: CurrentPage}) => {
        context.commit('setCurrentPage', data.page);
        context.dispatch('fetchProducts');
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    savePage(context: StoreContext): void {
      context.commit('startLoading');
      if (!context.state.currentPage.id) {
        Vue.prototype.$backendRequestService.post('next-gen-content-pages/create', context.state.currentPage).then(() => {
          context.commit('addNotification','Pagina succesvol aangemaakt', {root: true});
          router.push('/content');
        }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));

        return;
      }

      Vue.prototype.$backendRequestService.post(`next-gen-content-pages/${context.state.currentPage.id}/update`, context.state.currentPage).then(() => {
        context.commit('addNotification', 'Pagina succesvol opgeslagen', {root: true});
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true})).finally(() => context.commit('doneLoading'));
    },
    fetchProducts(context: StoreContext): void {
      if (!context.state.currentPage.pickedFranchise) {
        return;
      }

      Vue.prototype.$backendRequestService.get(`admin/products/${context.state.currentPage.pickedFranchise}`).then((data: {products: Product[]}) => {
        context.commit('setAllProducts', data.products);
      }).catch((error: AxiosError) => context.commit('addError', error, {root: true}))
    }
  },
  getters: {
    loading(state: DefaultState): boolean {
      return state.loading;
    },
    pages(state: DefaultState): ContentPage[] {
      return state.contentPages;
    },
    franchises(state: DefaultState): Franchise[] {
      return state.franchises;
    },
    pickedFranchise(state: DefaultState): string|null {
      return state.currentPage.pickedFranchise;
    },
    pickedProducts(state: DefaultState): string[] {
      return state.currentPage.pickedProducts;
    },
    allProducts(state: DefaultState): Product[] {
      return state.allProducts;
    },
    id(state: DefaultState): string|null {
      return state.currentPage.id;
    },
    title(state: DefaultState): string {
      return state.currentPage.title;
    },
    url(state: DefaultState): string {
      return state.currentPage.url;
    },
    metaDescription(state: DefaultState): string {
      return state.currentPage.metaDescription;
    },
    metaKeywords(state: DefaultState): string {
      return state.currentPage.metaKeywords;
    },
    headerImage(state: DefaultState): string {
      return state.currentPage.headerImage;
    },
    content(state: DefaultState): string {
      return state.currentPage.content;
    },
  }
}
