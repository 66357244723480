import VueI18n from "@/i18n";

const $d = (value: Date, type: string) => VueI18n.d(value, type);

export default class DateObject {
    date: Date;
    constructor(date: Date) {
        this.date = date;
    }

    static fromParameters(year: number, month: number, day: number, hours: number, minutes: number): DateObject {
        month -= 1;
        return new DateObject(new Date(year, month, day, hours, minutes, 0));
    }

    static fromDateString(dateString: string): DateObject {
        return new DateObject(new Date(dateString));
    }

    static now(): DateObject {
        return new DateObject(new Date());
    }

    static getDaysInMonth(year: number, month: number): number {
        return new Date(year, month + 1, 0).getDate();
    }

    addDays(daysToAdd: number): DateObject {
        const newDate = new Date(this.date.getTime());
        const date = newDate.getDate() + daysToAdd;
        newDate.setDate(date);
        return new DateObject(newDate);
    }

    year(): number {
        return this.date.getFullYear();
    }

    month(): number {
        return this.date.getMonth() + 1;
    }

    day(): number {
        return this.date.getDate();
    }

    toSystemDate(): string {
        const day = (this.date.getDate());
        let dayString = day.toString();
        if (day < 10) {
            dayString = '0' + day.toString();
        }

        const month = (this.date.getMonth() + 1);
        let monthString = month.toString();
        if (month < 10) {
            monthString = '0' + month.toString();
        }
        const year = this.date.getFullYear();
        return year + '-' + monthString + '-' + dayString;
    }

    toSystemDateTime(): string {
        return this.toSystemDate() + ' ' + this.toTimeString();
    }

    toLocaleDateString(): string {
        return $d(this.date, 'short');
    }

    toOnlyDateString(): string {
        return $d(this.date, 'onlyDate');
    }

    toLocaleDateTimeString(): string {
        return $d(this.date, 'dateTime');
    }

    isBefore(dateObject: DateObject): boolean {
        return this.date.getTime() < dateObject.date.getTime();
    }

    toLocaleTimeString(): string {
        return $d(this.date, 'time');
    }

    setDate(year: number, month: number, day: number): DateObject {
        return DateObject.fromParameters(year, month, day, this.date.getHours(), this.date.getMinutes());
    }

    setTime(hours: number, minutes: number): DateObject {
        const newDate = new Date(this.date.getTime());
        newDate.setHours(hours, minutes, 0);

        return new DateObject(newDate);
    }

    toTimeString(): string {
        const minutes = this.date.getMinutes();
        let minutesString = minutes.toString();
        if (minutes < 10) {
            minutesString = '0' + minutes.toString();
        }
        const hours = this.date.getHours();
        let hoursString = hours.toString();
        if (hours < 10) {
            hoursString = '0' + hours.toString();
        }
        return `${hoursString}:${minutesString}`;
    }
}
