import {Customer} from "./Customer";
import {Product} from "./Product";
import {OverviewOffer} from "./OverviewOffer";
import {PaymentMethod} from "@/components/Offer/ValueObject/PaymentMethod";
import DateObject from "@/service/DateObject";
import {OfferData} from "@/components/Offer/ValueObject/OfferData";

export default class DefaultState {
  loading = false;
  franchise: {id: string}|null = null;
  offerId: string|null = null;
  offerData: OfferData = {
    deliveryDate: DateObject.now().addDays(1).setTime(12, 0),
    costCenter: '',
    remark: '',
    paymentMethod: 'Factuur',
    deliveryCosts: 0,
    title: '',
    description: '',
  };
  customer: Customer|null = null;
  addresses: {
    deliveryAddress: string|null,
    invoiceAddress: string|null,
  } = {
    deliveryAddress: null,
    invoiceAddress: null,
  };
  paymentMethods: PaymentMethod[] = [];
  products: Product[] = [];
  sendOffer = false;
  overviewOffers: OverviewOffer[] = [];
}
