import {StoreContext} from "@/components/Reports/ValueObject/StoreContext";
import Vue from "vue";
import {AxiosError} from "axios";
import {DefaultState, Customer, NewCustomers} from "@/components/CustomerStatistics/ValueObject/DefaultState";
import DateObject from "@/service/DateObject";

export default {
  state: (): DefaultState => new DefaultState(),
  mutations: {
    setNewCustomers(state: DefaultState, newCustomers: NewCustomers[]): void {
      state.newCustomers = newCustomers;
    },
    setCustomerList(state: DefaultState, customers: Customer[]): void {
      state.customers = customers;
    },
    setCurrentYear(state: DefaultState, year: number): void {
      state.currentYear = year;
    },
    setCurrentMonth(state: DefaultState, month: number): void {
      state.currentMonth = month;
    },
  },
  actions: {
    fetchNewCustomers(context: StoreContext): void {
      Vue.prototype.$backendRequestService.get(`new-customers`, {year: context.getters.currentYear, month: context.getters.currentMonth})
        .then((data: { newCustomersPerDay: NewCustomers[], newCustomersList: Customer[] }) => {
          context.commit('setNewCustomers', data.newCustomersPerDay);
          context.commit(
            'setCustomerList',
            data.newCustomersList.map(
              (customer: Customer) => {
                if (typeof customer.createdDate === 'string') {
                  customer.createdDate = DateObject.fromDateString(customer.createdDate);
                }

                return customer;
              }
            )
          );
        })
        .catch((error: AxiosError) => context.commit('addError', error, {root: true}));
    }
  },
  getters: {
    newCustomers(state: DefaultState): NewCustomers[] {
      return state.newCustomers;
    },
    customerList(state: DefaultState): Customer[] {
      return state.customers;
    },
    years(state: DefaultState): number[] {
      let date = DateObject.now().year();
      let years: number[] = [];
      for (let i: number = 0; i < 5; i++) {
        years.unshift(date - i);
      }

      return years;
    },
    months(state: DefaultState): {text: string, value: number}[] {
      return state.months;
    },
    currentYear(state: DefaultState): number {
      return state.currentYear;
    },
    currentMonth(state: DefaultState): number {
      return state.currentMonth;
    },
  }
}
